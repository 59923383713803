import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  generateLastMonths,
  formatNumberFull,
  formatErrorForAlert,
  sortTicketRanges,
  assembleBody,
  assembleBodyWithProductType,
  findMax,
  fill3DSMissingData,
  compareAndAddDummy,
  mergeData,
  padArrayProgram,
  padArrayProgramAndSubchannel,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import Kpi from "../../components/Kpi/Kpi";
import KpiBig from "../../components/Kpi/KpiBig";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import McBarChartTrafficLight from "../../components/McCharts/McBarChartTrafficLight";
import LayoutAcquirersFilter from "../../components/Layout/LayoutAcquirersFilter";
import { _variablesTwoOranges } from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import LayoutFilter from "../../components/Layout/LayoutFilter";
import Switcher from "../../components/Switcher/Switcher";
import McBarChartStacked from "../../components/McCharts/McBarChartStacked";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import Download from "../../components/Button/Download";

const FraudAcquirers = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    userEmail,
    fraudDates,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  const [dateLabels, setDateLabels] = useState([]);

  // acquirers
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirers, setSelectedAcquirers] = useState(null);

  // chart
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState({});
  const [isLoadingByTicket, setIsLoadingByTicket] = useState(true);
  const [dataByTicket, setDataByTicket] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);

  // charts countries
  const [dataKpisAcquirers, setDataKpisAcquirers] = useState([]);
  const [dataByTicketAcquirers, setDataByTicketAcquirers] = useState([]);
  const [dataByProgramAcquirers, setDataByProgramAcquirers] = useState([]);
  // product type
  const [productType, setProductType] = useState("");
  const [transactionType, setTransactionType] = useState("count");
  // subchannel and program
  const [isLoadingBySubchannelAndProgram, setIsLoadingBySubchannelAndProgram] =
    useState(true);
  const [dataBySubchannelAndProgram, setDataBySubchannelAndProgram] = useState(
    []
  );
  const [
    dataBySubchannelAndProgramAcquirers,
    setDataBySubchannelAndProgramAcquirers,
  ] = useState([]);
  //top 5
  const [maxReasonCodeSplit, setMaxReasonCodeSplit] = useState([]);
  const [maxReasonCodeSplitAcquirers, setMaxReasonCodeSplitAcquirers] =
    useState([]);
  const [dataTopFiveReasonCodeSplit, setDataTopFiveReasonCodeSplit] = useState(
    []
  );
  const [
    dataTopFiveReasonCodeSplitAcquirers,
    setDataTopFiveReasonCodeSplitAcquirers,
  ] = useState([]);
  const [isLoadingTopFiveReasonCodeSplit, setIsLoadingTopFiveReasonCodeSplit] =
    useState(true);
  // merged data
  const [mergedDataByTicketRange, setMergedDataByTicketRange] = useState([]);
  const [
    mergedDataBySubchannelAndProgram,
    setMergedDataBySubchannelAndProgram,
  ] = useState([]);
  const [mergedDataByReasonCode, setMergedDataByReasonCode] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (fraudDates) {
      setDateLabels(fraudDates);
    } else {
      setAlert({
        message: "Fraud dates not available",
        messageType: "error",
      });
    }
  }, [fraudDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          "",
          productType
        );

        // get acquirers for select
        getAcquirers(body);

        getKpis(body);
        body["is_volume"] = transactionType === "count" ? false : true;

        getByTicket(body);
        getByProgram(body);
        getBySubchannelAndProgram(body);
        getTopFiveReasonCodeSplit(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyWithProductType(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        "",
        productType
      );

      getKpis(body);
      body["is_volume"] = transactionType === "count" ? false : true;

      getByTicket(body);
      getByProgram(body);
      getBySubchannelAndProgram(body);
      getTopFiveReasonCodeSplit(body);
    }
  }, [productType, transactionType]);

  // get charts data based on acquirer
  useEffect(() => {
    if (selectedAcquirers) {
      let body = assembleBodyAcquirer();

      getKpis(body, true);
      body["is_volume"] = transactionType === "count" ? false : true;

      getByTicket(body, true);
      getByProgram(body, true);
      getBySubchannelAndProgram(body, true);
      getTopFiveReasonCodeSplit(body, true);
    }
  }, [
    timePeriod,
    dateLabels,
    selectedAcquirers,
    acquirers,
    productType,
    transactionType,
  ]);

  /* useEffect(() => {
    if (!isLoadingByProgram) {
      let data = [];
      dataByProgram.forEach((obj) => {
        const correspondingObj = dataByProgramAcquirers.find(
          (objCountries) => objCountries.label === obj.label
        );

        if (correspondingObj) {
          data.push(correspondingObj);
        } else {
          data.push({
            label: obj.label,
            value: 0,
          });
        }
      });
      setDataByProgramAcquirers(data);
    }
  }, [dataByProgram, isLoadingByProgram]); */

  const isLoadingAll = () => {
    setIsLoadingKpis(true);
    setIsLoadingByTicket(true);
    setIsLoadingByProgram(true);
    setIsLoadingBySubchannelAndProgram(true);
    setIsLoadingTopFiveReasonCodeSplit(true);
  };

  const getAcquirers = (body) => {
    FetchService.post("/api/dashboard/acquirers/fraud", body)
      .then((res) => {
        let tempAcquirers = res.data.acquirers.sort((a, b) =>
          a.localeCompare(b)
        );
        tempAcquirers = tempAcquirers.map((el) => {
          return {
            value: el,
            text: el,
          };
        });

        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const assembleBodyAcquirer = () => {
    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      undefined,
      undefined,
      productType,
      undefined
    );

    if (selectedAcquirers !== 0 && selectedAcquirers !== "0") {
      body.acquirer_name = selectedAcquirers;
    }

    return body;
  };

  const handleSelectAcquirer = (e) => {
    setSelectedAcquirers(e);
  };

  const getKpis = (body, isAcquirers) => {
    setIsLoadingKpis(true);

    FetchService.post(`/api/dashboard/fraud/acquirer/performance`, body)
      .then((res) => {
        isAcquirers ? setDataKpisAcquirers(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getByTicket = (body, isAcquirers) => {
    setIsLoadingByTicket(true);

    FetchService.post(
      `/api/dashboard/fraud/acquirer/fraud-rate-by-ticket-range`,
      body
    )
      .then((res) => {
        res.data.forEach((el) => (el.label = el.label + " €"));
        isAcquirers
          ? setDataByTicketAcquirers(sortTicketRanges(res.data, "€"))
          : setDataByTicket(sortTicketRanges(res.data, "€"));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicket(false));
  };

  const getByProgram = (body, isAcquirers) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      `/api/dashboard/fraud/acquirer/fraud-rate-by-program`,
      body
    )
      .then((res) => {
        isAcquirers
          ? setDataByProgramAcquirers(fill3DSMissingData(res.data))
          : setDataByProgram(fill3DSMissingData(res.data));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const getBySubchannelAndProgram = (body, isAcquirers) => {
    setIsLoadingBySubchannelAndProgram(true);

    FetchService.post(
      "/api/dashboard/fraud/acquirer/fraud-rate-by-subchannel-and-program",
      body
    )
      .then((res) => {
        isAcquirers
          ? setDataBySubchannelAndProgramAcquirers(res.data)
          : setDataBySubchannelAndProgram(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannelAndProgram(false));
  };

  const getTopFiveReasonCodeSplit = (body, isAcquirers) => {
    setIsLoadingTopFiveReasonCodeSplit(true);

    FetchService.post(
      "/api/dashboard/fraud/acquirer/top-5-fraud-reason-code",
      body
    )
      .then((res) => {
        let sortData = res.data.sort((a, b) => b.percent - a.percent);
        if (isAcquirers) {
          setMaxReasonCodeSplitAcquirers(findMax(sortData));
          setDataTopFiveReasonCodeSplitAcquirers(res.data);
        } else {
          setMaxReasonCodeSplit(findMax(sortData));
          setDataTopFiveReasonCodeSplit(res.data);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFiveReasonCodeSplit(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  useEffect(() => {
    setMergedDataByTicketRange(
      mergeData(
        dataByTicket,
        compareAndAddDummy(
          dataByTicketAcquirers,
          dataByTicket,
          "label",
          "value"
        ),
        [{ source: "value", target: "value_merchant" }],
        [{ source: "value", target: "value_acquirer" }],
        "label"
      )
    );
  }, [dataByTicket, dataByTicketAcquirers]);

  useEffect(() => {
    setMergedDataByReasonCode(
      mergeData(
        dataTopFiveReasonCodeSplit,
        compareAndAddDummy(
          dataTopFiveReasonCodeSplitAcquirers,
          dataTopFiveReasonCodeSplit,
          "label",
          "percent"
        ),
        [{ source: "percent", target: "percent_merchant" }],
        [{ source: "percent", target: "percent_acquirer" }],
        "label"
      )
    );
  }, [dataTopFiveReasonCodeSplit, dataTopFiveReasonCodeSplitAcquirers]);

  useEffect(() => {
    setMergedDataBySubchannelAndProgram(
      mergeData(
        dataBySubchannelAndProgram,
        compareAndAddDummy(
          dataBySubchannelAndProgramAcquirers,
          dataBySubchannelAndProgram,
          "subchannel",
          "rate_3ds",
          "rate_non_3ds"
        ),
        [
          { source: "rate_3ds", target: "rate_3ds_merchant" },
          { source: "rate_non_3ds", target: "rate_non_3ds_merchant" },
        ],
        [
          { source: "rate_3ds", target: "rate_3ds_acquirer" },
          { source: "rate_non_3ds", target: "rate_non_3ds_acquirer" },
        ],
        "subchannel"
      )
    );
  }, [dataBySubchannelAndProgram, dataBySubchannelAndProgramAcquirers]);

  return (
    <LayoutPageContainer
      title={"Fraud"}
      pages={[
        {
          title: "Overview",
          link: "/fraud/home",
        },
        { title: "Geography", link: "/fraud/geography" },
        { title: "Benchmark", link: "/fraud/benchmark" },
        isMultiAcquirer && !acquirerName
          ? { title: "Acquirers", link: "/fraud/acquirers", active: true }
          : {},
      ]}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingKpis,
          isLoadingByTicket,
          isLoadingByProgram,
          isLoadingTopFiveReasonCodeSplit,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}

      <hr />
      <FilterBoxSticky>
        <LayoutAcquirersFilter
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions.<br /><br />" +
            "Average genuine/fraudulent tickets are calculated as: volumes (spend) divided by transactions.<br /><br />" +
            "100 bps = 1%"
          }
          tooltipAcquirer={
            "Acquirer performance indicates the performances of selected Acquirer.<br /><br />" +
            "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions.<br /><br />" +
            "Average genuine/fraudulent tickets are calculated as: volumes (spend) divided by transactions.<br /><br />" +
            "100 bps = 1%"
          }
          options={acquirers}
          onChange={handleSelectAcquirer}
          hideNames={userEmail === "demo@mastercard.it"}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <div>
              <KpiBig
                bottomContent={
                  (dataKpis.fraud_rate
                    ? formatNumberFull(dataKpis.fraud_rate)
                    : 0) + "bps"
                }
                topContent={"Fraud rate"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpis.fraud_trx
                    ? formatNumberFull(dataKpis.fraud_trx / 1000)
                    : 0) + " K"
                }
                rightContent={"Fraudulent transactions"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpis.fraud_amount
                    ? formatNumberFull(dataKpis.fraud_amount)
                    : 0) + " €"
                }
                rightContent={"Fraudulent amount"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpis.avg_genuine_ticket
                    ? formatNumberFull(dataKpis.avg_genuine_ticket)
                    : 0) + " €"
                }
                rightContent={"Average genuine ticket"}
                color={_variablesTwoOranges.primaryOrangeLight}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpis.avg_fraud_ticket
                    ? formatNumberFull(dataKpis.avg_fraud_ticket)
                    : 0) + " €"
                }
                rightContent={"Average fraudulent ticket"}
                styleClass="mb-3"
              />

              <hr style={{ marginBottom: "5.6rem" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2rem",
                }}
              >
                <Switcher
                  isShared={true}
                  sharedState={transactionType}
                  pages={[
                    {
                      title: "Transactions",
                      value: "count",
                      onClick: () => {
                        setTransactionType("count");
                      },
                    },
                    {
                      title: "Volumes",
                      value: "volume",
                      onClick: () => {
                        setTransactionType("volume");
                      },
                    },
                  ]}
                />
              </div>
              <SmallTitleFlex
                title={"Fraud rate by ticket range"}
                tooltip={
                  "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each ticket range.<br /><br />" +
                  "100 bps = 1%"
                }
              />
              <McBarChartTrafficLight
                height={
                  60 +
                  (dataByTicket.length > 0 ? dataByTicket.length * 50 : 300)
                }
                data={dataByTicket}
                dataGuide={{
                  y: "label",
                  barsData: [{ dataName: "value", friendlyName: "Fraud rate" }],
                }}
                tooltipMeasureUnit=" bps"
                marginLeft
              />
              <hr />
              {dashboardVersion2 ? (
                <>
                  <SmallTitleFlex
                    title={"Fraud rate by subchannel & program"}
                    tooltip={
                      "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program and subchannel.<br /><br />" +
                      "100 bps = 1%"
                    }
                  />
                  <McBarChartStacked
                    height={
                      120 +
                      (dataBySubchannelAndProgram.length > 0
                        ? dataBySubchannelAndProgram.length * 60
                        : 120)
                    }
                    data={dataBySubchannelAndProgram}
                    dataGuide={{
                      y: "subchannel",
                      barsData: [
                        {
                          firstBar: "rate_3ds",
                          secondBar: "rate_non_3ds",
                          friendlyName: "Fraud rate by subchannel and program",
                        },
                      ],
                    }}
                    tooltipMeasureUnit=" bps"
                    marginLeft
                    background={false}
                  />
                </>
              ) : (
                <>
                  <SmallTitleFlex
                    title={"Fraud rate by program"}
                    tooltip={
                      "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program.<br /><br />" +
                      "100 bps = 1%"
                    }
                  />
                  <McBarChartTrafficLight
                    height={
                      60 +
                      (dataByProgram.length > 0
                        ? dataByProgram.length * 60
                        : 120)
                    }
                    data={dataByProgram}
                    dataGuide={{
                      y: "label",
                      barsData: [
                        { dataName: "value", friendlyName: "Fraud rate" },
                      ],
                    }}
                    tooltipMeasureUnit=" bps"
                    marginLeft
                  />
                </>
              )}
              {dashboardVersion2 && (
                <>
                  <hr />
                  <SmallTitleFlex
                    title={"Fraud transaction share by reason code"}
                    tooltip={
                      "Fraud transactions share split over the different fraud reason codes"
                    }
                  />
                  <McBarChartVertical
                    data={dataTopFiveReasonCodeSplit}
                    dataGuide={{
                      y: "label",
                      barsData: [
                        { dataName: "percent", friendlyName: "Percentage" },
                      ],
                    }}
                    labels={["Fraud Categories"]}
                    twoYs={false}
                    maxData={maxReasonCodeSplit}
                    height={400}
                    isPercentage={true}
                  />
                </>
              )}
            </div>
          </>
        }
        rightContent={
          <>
            <div>
              <KpiBig
                bottomContent={
                  (dataKpisAcquirers.fraud_rate
                    ? formatNumberFull(dataKpisAcquirers.fraud_rate)
                    : 0) + " bps"
                }
                topContent={"Fraud rate"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpisAcquirers.fraud_trx
                    ? formatNumberFull(dataKpisAcquirers.fraud_trx / 1000)
                    : 0) + " K"
                }
                rightContent={"Fraudulent transactions"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpisAcquirers.fraud_amount
                    ? formatNumberFull(dataKpisAcquirers.fraud_amount)
                    : 0) + " €"
                }
                rightContent={"Fraudulent amount"}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpisAcquirers.avg_genuine_ticket
                    ? formatNumberFull(dataKpisAcquirers.avg_genuine_ticket)
                    : 0) + " €"
                }
                rightContent={"Average genuine ticket"}
                color={_variablesTwoOranges.primaryOrangeLight}
                styleClass="mb-3"
              />
              <Kpi
                leftContent={
                  (dataKpisAcquirers.avg_fraud_ticket
                    ? formatNumberFull(dataKpisAcquirers.avg_fraud_ticket)
                    : 0) + " €"
                }
                rightContent={"Average fraudulent ticket"}
                styleClass="mb-3"
              />

              <hr style={{ marginBottom: "2rem" }} />
              <div className="d-flex justify-content-end">
                <div style={{ marginBottom: "1rem" }}>
                  <Download
                    disabled={selectedAcquirers === null}
                    section="FraudAcquirers"
                    chartName="Fraud rate by ticket range"
                    data={mergedDataByTicketRange}
                    graphType="pie"
                    label={"Ticket Range"}
                    productType={productType}
                    acquirer={selectedAcquirers}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    measure={
                      transactionType === "count"
                        ? "Transaction Count"
                        : "Volumes"
                    }
                    fields={[
                      { field: "Fraud Rate Merchant", value: "value_merchant" },
                      { field: "Fraud Rate Acquirer", value: "value_acquirer" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2rem",
                }}
              >
                <Switcher
                  isShared={true}
                  sharedState={transactionType}
                  pages={[
                    {
                      title: "Transactions",
                      value: "count",
                      onClick: () => {
                        setTransactionType("count");
                      },
                    },
                    {
                      title: "Volumes",
                      value: "volume",
                      onClick: () => {
                        setTransactionType("volume");
                      },
                    },
                  ]}
                />
              </div>
              <SmallTitleFlex
                title={"Fraud rate by ticket range"}
                tooltip={
                  "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each ticket range.<br /><br />" +
                  "100 bps = 1%"
                }
              />
              <McBarChartTrafficLight
                height={
                  60 +
                  (dataByTicketAcquirers.length > 0
                    ? dataByTicketAcquirers.length * 50
                    : 300)
                }
                data={dataByTicketAcquirers}
                dataGuide={{
                  y: "label",
                  barsData: [{ dataName: "value", friendlyName: "Fraud rate" }],
                }}
                tooltipMeasureUnit="bps"
                marginLeft
              />
              <hr />
              {dashboardVersion2 ? (
                <>
                  <div className="d-flex justify-content-between">
                    <SmallTitleFlex
                      title={"Fraud rate by subchannel & program"}
                      tooltip={
                        "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program and subchannel.<br /><br />" +
                        "100 bps = 1%"
                      }
                    />
                    <Download
                      disabled={selectedAcquirers === null}
                      section="FraudAcquirers"
                      chartName="Fraud rate by subchannel & program"
                      data={mergedDataBySubchannelAndProgram}
                      graphType="pie"
                      label={"Subchannel "}
                      productType={productType}
                      acquirer={selectedAcquirers}
                      timeRange={
                        dateLabels[timePeriod[0]] +
                        " - " +
                        dateLabels[timePeriod[1]]
                      }
                      fields={[
                        { field: "3DS Merchant", value: "rate_3ds_merchant" },
                        { field: "3DS Acquirer", value: "rate_3ds_acquirer" },
                        {
                          field: "Non 3DS Merchant",
                          value: "rate_non_3ds_merchant",
                        },
                        {
                          field: "Non 3DS Acquirer",
                          value: "rate_non_3ds_acquirer",
                        },
                      ]}
                      iconSize="sm"
                      className="h-8 w-8"
                    />
                  </div>
                  <McBarChartStacked
                    height={
                      120 +
                      (dataBySubchannelAndProgram.length > 0
                        ? dataBySubchannelAndProgram.length * 60
                        : 120)
                    }
                    data={padArrayProgramAndSubchannel(
                      dataBySubchannelAndProgramAcquirers,
                      dataBySubchannelAndProgram
                    )}
                    dataGuide={{
                      y: "subchannel",
                      barsData: [
                        {
                          firstBar: "rate_3ds",
                          secondBar: "rate_non_3ds",
                          friendlyName: "Fraud rate by subchannel and program",
                        },
                      ],
                    }}
                    tooltipMeasureUnit="bps"
                    marginLeft
                    background={false}
                  />
                </>
              ) : (
                <>
                  <SmallTitleFlex
                    title={"Fraud rate by program"}
                    tooltip={
                      "Fraud rate is calculated as: incidence of fraudulent transactions on total transactions, per each program.<br /><br />" +
                      "100 bps = 1%"
                    }
                  />
                  <McBarChartTrafficLight
                    height={60 + dataByProgram.length * 60}
                    data={padArrayProgram(
                      dataByProgramAcquirers,
                      dataByProgram
                    )}
                    dataGuide={{
                      y: "label",
                      barsData: [
                        { dataName: "value", friendlyName: "Fraud rate" },
                      ],
                    }}
                    tooltipMeasureUnit=" bps"
                    marginLeft
                  />
                </>
              )}
              {dashboardVersion2 && (
                <>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <SmallTitleFlex
                      title={"Fraud transaction share by reason code"}
                      tooltip={
                        "Fraud transactions share split over the different fraud reason codes"
                      }
                    />
                    <Download
                      disabled={selectedAcquirers === null}
                      section="FraudAcquirers"
                      chartName="Transaction share by reason code"
                      data={mergedDataByReasonCode}
                      graphType="pie"
                      label={"Reason Code"}
                      productType={productType}
                      acquirer={selectedAcquirers}
                      timeRange={
                        dateLabels[timePeriod[0]] +
                        " - " +
                        dateLabels[timePeriod[1]]
                      }
                      fields={[
                        {
                          field: "Percent Merchant (%)",
                          value: "percent_merchant",
                        },
                        {
                          field: "Percent Acquirer (%)",
                          value: "percent_acquirer",
                        },
                      ]}
                      iconSize="sm"
                      className="h-8 w-8"
                    />
                  </div>
                  <McBarChartVertical
                    data={dataTopFiveReasonCodeSplitAcquirers}
                    dataGuide={{
                      y: "label",
                      barsData: [
                        { dataName: "percent", friendlyName: "Percentage" },
                      ],
                    }}
                    labels={["Fraud Categories"]}
                    twoYs={false}
                    maxData={maxReasonCodeSplitAcquirers}
                    height={400}
                    isPercentage={true}
                  />
                </>
              )}
            </div>
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default FraudAcquirers;
