import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberRound,
  formatNumberLocale,
  formatErrorForAlert,
  findMax,
  sortTicketRanges,
  assembleBody,
  ukMerchants,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import McBarChart from "../../components/McCharts/McBarChart";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import McDonutChartDouble from "../../components/McCharts/McDonutChartDouble";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import KpiRow from "../../components/Kpi/KpiRow";
import FilterBox from "../../components/FilterBox/FilterBox";
import Select from "../../components/Select/Select";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import {
  _variablesTwoGrey,
  _variablesSix,
  _variablesThreeOrangesRate,
  _variablesTwoOranges,
} from "../../styles/_variables";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import TableAG from "../../components/Table/TableAG";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import SmallTitle from "../../components/Title/SmallTitle";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import Switcher from "../../components/Switcher/Switcher";
import Download from "../../components/Button/Download";
import { ClimbingBoxLoader } from "react-spinners";

const colsDef = [
  { field: "auth_method", headerName: "Authentication Method", flex: 1 },
  {
    headerName: "Challenge Code",
    marryChildren: true,
    children: [
      {
        field: "percent_zerofour",
        headerName: "04",
        flex: 1,
      },
      { field: "percent_zerofive", headerName: "05", flex: 1 },
    ],
  },
  {
    field: "total_trx",
    headerName: "Total Transaction Count",
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString("en-US"),
  },
];

const AuthenticationHome = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    currency,
    autheDates,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // charts
  const [isLoadingPerformance, setIsLoadingPerformance] = useState(true);
  const [dataPerformance, setDataPerformance] = useState([]);
  const [isLoadingTicketAverage, setIsLoadingTicketAverage] = useState(true);
  const [dataTicketAverage, setDataTicketAverage] = useState([]);
  const [isLoadingFrictionless, setIsLoadingFrictionless] = useState(true);
  const [dataFrictionless, setDataFrictionless] = useState([]);
  const [sideTextRowsFrictionless, setSideTextRowsFrictionless] = useState([]);
  const [isLoadingChallengeSuccess, setIsLoadingChallengeSuccess] =
    useState(true);
  const [dataChallengeSuccess, setDataChallengeSuccess] = useState([]);
  const [sideTextRowsChallengeSuccess, setSideTextRowsChallengeSuccess] =
    useState([]);
  const [selectedOptionFilterOne, setSelectedOptionFilterOne] = useState(null);
  const [selectedOptionFilterTwo, setSelectedOptionFilterTwo] = useState(null);
  const [isLoadingTransactionsDevice, setIsLoadingTransactionsDevice] =
    useState(true);
  const [dataTransactionsDevice, setDataTransactionsDevice] = useState([]);
  const [sideBarsTransactionsDevice, setSideBarsTransactionsDevice] = useState(
    []
  );
  const [
    isLoadingTransactionsTicketRange,
    setIsLoadingTransactionsTicketRange,
  ] = useState(true);
  const [dataTransactionsTicketRange, setDataTransactionsTicketRange] =
    useState([]);
  const [isLoadingDecline, setIsLoadingDecline] = useState(true);
  const [dataDecline, setDataDecline] = useState([]);

  // select options
  const selectOptionsFilterOne = [
    { value: "0", text: "Total transactions" },
    { value: "Y", text: "Authentication success" },
    { value: "N", text: "Authentication failed" },
  ];
  const selectOptionsFilterTwo = [
    { value: "0", text: "Challenge and frictionless" },
    { value: "Y", text: "Frictionless only" },
    { value: "N", text: "Challenge only" },
  ];

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;
  const currencySymbol = currency === 826 ? "£" : "€";

  // others
  const [maxData, setMaxData] = useState(0);

  // challenge code
  const [dataChallengeCode, setDataChallengeCode] = useState([]);
  const [isLoadingChallengeCode, setIsLoadingChallengeCode] = useState();
  const [dataChallengeCodeForAuthMethod, setDataChallengeCodeForAuthMethod] =
    useState([]);
  const [
    isLoadingChallengeCodeForAuthMethod,
    setIsLoadingChallengeCodeForAuthMethod,
  ] = useState();
  // 3ds2 versions
  const [data3ds2Versions, setData3ds2Versions] = useState([]);
  const [data3ds2VersionsBarChart, setData3ds2VersionsBarChart] = useState([]);
  const [isLoading3ds2Versions, setIsLoading3ds2Versions] = useState(true);
  const [export3ds2Versions, setExport3ds2Versions] = useState([]);
  // authentication methods
  const [dataAuthMethods, setDataAuthMethods] = useState([]);
  const [dataTopFiveAuthMethods, setDataTopFiveAuthMethods] = useState([]);
  const [exportAuthMethods, setExportAuthMethods] = useState([]);
  const [isLoadingAuthMethods, setIsLoadingAuthMethods] = useState(true);
  // transaction type
  const [transactionType, setTransactionType] = useState("count");

  // get dates for timeperiod
  useEffect(() => {
    if (autheDates) {
      setDateLabels(autheDates);
    } else {
      setAlert({
        message: "Authentication dates not available",
        messageType: "error",
      });
    }
  }, [autheDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          transactionType === "count" ? false : true,
          undefined,
          currency
        );

        getPerformance(body);
        getTicketAverage(body);
        getFrictionless(body);
        getChallengeSuccess(body);
        getTransactionsDevice(body);
        getTransactionsTicketRange(body);
        getDecline(body);
        getChallengeCode(body);
        getChallengeCodeForAuthMethod(body);
        get3ds2Versions(body);
        getAuthMethods(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels]);

  // get charts data based on timeperiod
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBody(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        transactionType === "count" ? false : true,
        undefined,
        currency
      );

      getPerformance(body);
    }
  }, [transactionType]);

  const isLoadingAll = () => {
    setIsLoadingPerformance(true);
    setIsLoadingTicketAverage(true);
    setIsLoadingFrictionless(true);
    setIsLoadingChallengeSuccess(true);
    setIsLoadingTransactionsDevice(true);
    setIsLoadingTransactionsTicketRange(true);
    setIsLoadingDecline(true);
    setIsLoadingChallengeCode(true);
    setIsLoadingChallengeCodeForAuthMethod(true);
    setIsLoading3ds2Versions(true);
    setIsLoadingAuthMethods(true);
  };

  const getPerformance = (body) => {
    setIsLoadingPerformance(true);

    FetchService.post(
      "/api/dashboard/authe/authe-trends/authentication-rate-performance",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        res.data.forEach((el) => {
          el.ok = el.ok ? el.ok / 1000 : 0;
          el.ko = el.ko ? el.ko / 1000 : 0;
          el.total = el.total ? el.total * 100 : 0;
        });
        setDataPerformance(res.data);

        // find max for better chart visualization
        setMaxData(findMax(res.data));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingPerformance(false));
  };

  const getTicketAverage = (body) => {
    setIsLoadingTicketAverage(true);

    FetchService.post("/api/dashboard/authe/authe-trends/ticket-average", body)
      .then((res) => {
        if (res.data.length === 0) return;
        let temp = [];
        let foundElement = res.data.find((el) => el.label === "Y");
        temp.push(formatNumberFull(foundElement ? foundElement.value : 0));
        foundElement = res.data.find((el) => el.label === "N");
        temp.push(formatNumberFull(foundElement ? foundElement.value : 0));
        setDataTicketAverage(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTicketAverage(false));
  };

  const getFrictionless = (body) => {
    setIsLoadingFrictionless(true);

    FetchService.post(
      "/api/dashboard/authe/authe-trends/percentage-frictionless-transaction",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let frictionless = res.data.frictionless ? res.data.frictionless : 0;
        let acquirer = res.data.acquirer_exemption
          ? res.data.acquirer_exemption
          : 0;
        let tempData = [
          [
            { name: "Frictionless transactions", value: frictionless },
            { name: "Challenge transactions", value: 100 - frictionless },
          ],
          [
            { name: "With Acquirer exemptions", value: acquirer },
            { name: "Without Acquirer exemptions", value: 100 - acquirer },
          ],
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(frictionless) + "%",
            rightContent: "Percentage of frictionless transactions",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
          {
            leftContent: formatNumberFull(acquirer) + "%",
            rightContent: "Of which Acquirer exemptions",
            color: _variablesThreeOrangesRate.primaryOrangeLight,
          },
        ];
        setDataFrictionless(tempData);
        setSideTextRowsFrictionless(tempRows);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingFrictionless(false));
  };

  const getChallengeSuccess = (body) => {
    setIsLoadingChallengeSuccess(true);

    FetchService.post(
      "/api/dashboard/authe/authe-trends/challenge-success-rate",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let success = res.data.data ? res.data.data : 0;
        let tempData = [
          { name: "Challenge success rate", value: success },
          { name: "Challenge failure rate", value: 100 - success },
        ];
        let tempRows = [
          {
            leftContent: formatNumberFull(success) + "%",
            rightContent: "Challenge success rate",
            color: _variablesThreeOrangesRate.primaryOrange,
          },
        ];

        setDataChallengeSuccess(tempData);
        setSideTextRowsChallengeSuccess(tempRows);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeSuccess(false));
  };

  const getTransactionsDevice = (body) => {
    setIsLoadingTransactionsDevice(true);

    FetchService.post(
      "/api/dashboard/authe/authe-trends/transaction-per-device",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let tempData = [];
        let tempBars = [];

        let web = res.data.find((el) => el.label === "Browser-based");
        tempData.push({
          name: "Web",
          value: web ? web.value : "0",
        });
        tempBars.push({
          name: "Web",
          value: web.auth_rate ? web.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrange,
          text: "Web",
        });

        let app = res.data.find((el) => el.label === "App-based");
        tempData.push({
          name: "App",
          value: app ? app.value : "0",
        });
        tempBars.push({
          name: "App",
          value: app ? app.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrangeLight,
          text: "App",
        });

        setDataTransactionsDevice(tempData);
        setSideBarsTransactionsDevice(tempBars);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsDevice(false));
  };

  const getTransactionsTicketRange = (body) => {
    setIsLoadingTransactionsTicketRange(true);

    FetchService.post(
      "/api/dashboard/authe/authe-trends/transaction-by-ticket-range",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        res.data.forEach((el) => {
          el.label = el.label ? el.label + ` ${currencySymbol}` : "";
          el.value = el.value ? el.value : 0;
          el.auth_rate = el.auth_rate ? el.auth_rate : 0;
        });
        setDataTransactionsTicketRange(
          sortTicketRanges(res.data, currencySymbol)
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsTicketRange(false));
  };

  const getDecline = (body) => {
    setIsLoadingDecline(true);

    FetchService.post("/api/dashboard/authe/authe-trends/decline-reason", body)
      .then((res) => {
        if (res.data.length === 0) return;
        let temp = [];
        res.data.forEach((el) => {
          temp.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        setDataDecline(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDecline(false));
  };

  const getChallengeCode = (body) => {
    setIsLoadingChallengeCode(true);
    FetchService.post("/api/dashboard/authe/authe-trends/challenge-code", body)
      .then((res) => {
        if (res.data.length === 0) return;
        let tempData = res.data.map((el) => {
          return {
            label: el.label ? el.label : "",
            value: el.percent ? el.percent : 0,
          };
        });
        setDataChallengeCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCode(false));
  };

  const get3ds2Versions = (body) => {
    setIsLoading3ds2Versions(true);
    FetchService.post("/api/dashboard/authe/authe-trends/3ds2-versions", body)
      .then((res) => {
        if (res.data.length === 0) return;
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        setExport3ds2Versions(cleanRes);

        setData3ds2Versions(
          cleanRes
            .filter((el) => el.trx_share >= 0.1)
            .map((el) => {
              return {
                name: el.label ? el.label : "",
                value: el.trx_share ? el.trx_share : 0,
              };
            })
        );

        setData3ds2VersionsBarChart(
          cleanRes
            .filter((el) => el.success_rate >= 0.1)
            .map((el) => {
              return {
                name: el.label ? el.label : "",
                value: el.success_rate ? el.success_rate : 0,
              };
            })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3ds2Versions(false));
  };

  const getChallengeCodeForAuthMethod = (body) => {
    setIsLoadingChallengeCodeForAuthMethod(true);
    FetchService.post(
      "/api/dashboard/authe/authe-trends/challenge-code-for-auth-method",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let tempData = res.data;
        let groupedData = [];

        // group records with the same auth method to display them in the table
        for (let i = 0; i < tempData.length - 2; i += 1) {
          if (tempData[i].auth_method === tempData[i + 1].auth_method) {
            let percent_zerofour = "-";
            let percent_zerofive = "-";

            if (res.data[i].challenge_desc.substring(0, 2) === "04") {
              percent_zerofour = formatNumberRound(res.data[i].percent) + "%";
              percent_zerofive =
                formatNumberRound(res.data[i + 1].percent) + "%";
            } else if (res.data[i].challenge_desc.substring(0, 2) === "05") {
              percent_zerofour =
                formatNumberRound(res.data[i + 1].percent) + "%";
              percent_zerofive = formatNumberRound(res.data[i].percent) + "%";
            }
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: percent_zerofour,
              percent_zerofive: percent_zerofive,
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
            // skip next record since you grouped it with the current one
            i += 1;
          }
          // in case the record has no matching 04 type or 05 type record
          else if (res.data[i].challenge_desc.substring(0, 2) === "04") {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: formatNumberRound(res.data[i].percent) + "%",
              percent_zerofive: "-",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          } else {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: "-",
              percent_zerofive: formatNumberRound(res.data[i].percent) + "%",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          }
        }

        groupedData.sort((a, b) => b.total_trx - a.total_trx);

        setDataChallengeCodeForAuthMethod(groupedData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCodeForAuthMethod(false));
  };

  const getAuthMethods = (body) => {
    setIsLoadingAuthMethods(true);
    FetchService.post(
      "/api/dashboard/authe/authe-trends/authentication-methods",
      body
    )
      .then((res) => {
        if (res.data.length === 0) return;
        let cleanRes = res.data; /*.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );*/
        setExportAuthMethods(cleanRes);

        setDataAuthMethods(
          cleanRes.map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.trx_share ? el.trx_share : 0,
            };
          })
        );

        setDataTopFiveAuthMethods(
          cleanRes.map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.success_rate ? el.success_rate : 0,
            };
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAuthMethods(false));
  };

  const handleOnChangeFilterOne = (value) => {
    setSelectedOptionFilterOne(value);

    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      acquirerName,
      transactionType === "count" ? false : true,
      undefined,
      currency
    );
    if (value !== "0" && value !== null) {
      body.flg_ok_ko = value;
    }
    if (selectedOptionFilterTwo !== "0" && selectedOptionFilterTwo !== null) {
      body.flg_frictionless = selectedOptionFilterTwo;
    }

    getTransactionsDevice(body);
    getTransactionsTicketRange(body);
  };

  const handleOnChangeFilterTwo = (value) => {
    setSelectedOptionFilterTwo(value);

    let body = assembleBody(
      merchantName,
      dateLabels[timePeriod[0]],
      dateLabels[timePeriod[1]],
      acquirerName,
      transactionType === "count" ? false : true,
      undefined,
      currency
    );
    if (value !== "0" && value !== null) {
      body.flg_frictionless = value;
    }
    if (selectedOptionFilterOne !== "0" && selectedOptionFilterOne !== null) {
      body.flg_ok_ko = selectedOptionFilterOne;
    }

    getTransactionsDevice(body);
    getTransactionsTicketRange(body);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authentication/home",
      active: true,
    },
    { title: "Geography", link: "/authentication/geography" },
    { title: "Benchmark", link: "/authentication/benchmark" },
    { title: "Acquirers", link: "/authentication/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authentication/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  return (
    <LayoutPageContainer
      title={"Authentication"}
      pages={pages}
      footer="authe"
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingPerformance,
          isLoadingTicketAverage,
          isLoadingFrictionless,
          isLoadingChallengeSuccess,
          isLoadingTransactionsDevice,
          isLoadingTransactionsTicketRange,
          isLoadingDecline,
          isLoadingChallengeCode,
          isLoadingChallengeCodeForAuthMethod,
          isLoading3ds2Versions,
          isLoadingAuthMethods,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {/*    <FilterBoxSingle>
        <LayoutCardFilter
          options={[
            { value: "credit", text: "All products" },
            { value: "credit", text: "Credit" },
            { value: "credit", text: "Debit" },
            { value: "credit", text: "Prepaid" },
          ]}
        />
      </FilterBar>
       */}
      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}
      <SmallTitleFlex
        title={"Authentication performance"}
        tooltip={
          "Number of monthly card not present transactions split by authentication outcome (successful and failed).<br /><br />" +
          "The green line indicates the Authentication rate calculated as: number of successful authentications divided by total transactions.<br /><br />" +
          "Average ticket is calculated as: volumes (spend) divided by transactions."
        }
      />
      {/* <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      /> */}

      <div className="d-flex justify-content-end">
        <Download
          section="AutheOverview"
          chartName="Authentication performance"
          data={dataPerformance}
          graphType="barChart"
          label={"Month"}
          field1={"Authentication success"}
          value1={"ok"}
          field2={"Authentication failed"}
          value2={"ko"}
          field3={"Authentication rate (%)"}
          value3={"total"}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>

      <McBarChart
        data={dataPerformance}
        dataGuide={{
          x: "yearmonth",
          barsData: [
            { dataName: "ok", friendlyName: "Authentication success" },
            { dataName: "ko", friendlyName: "Authentication failed" },
          ],
          rateData: {
            dataName: "total",
            friendlyName: "Authentication rate",
          },
        }}
        labels={[
          transactionType === "count"
            ? "Transactions (K)"
            : "Volumes" + ` (K${currencySymbol})`,
          "Rate (%)",
        ]}
        palette={_variablesThreeOrangesRate}
        maxData={maxData}
        tooltipMeasureUnit={
          transactionType === "count" ? " K" : ` (K${currencySymbol})`
        }
      />

      {!ukMerchants.includes(merchantName) && (
        <div className="mt-5">
          <KpiRow
            offset
            kpis={[
              {
                leftContent:
                  formatNumberLocale(
                    dataTicketAverage[0] ? dataTicketAverage[0] : "0"
                  ) + ` ${currencySymbol}`,
                righContent: (
                  <div>
                    <b>Average ticket</b>
                    <br />
                    Authentication success
                  </div>
                ),
              },
              {
                leftContent:
                  formatNumberLocale(
                    dataTicketAverage[1] ? dataTicketAverage[1] : "0"
                  ) + ` ${currencySymbol}`,
                righContent: (
                  <div>
                    <b>Average ticket</b>
                    <br />
                    Authentication failed
                  </div>
                ),
                color: _variablesThreeOrangesRate.primaryOrangeLight,
              },
            ]}
          />
        </div>
      )}

      <hr />

      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitleFlex
              title={"Frictionless penetration"}
              tooltip={
                "Frictionless transactions do not request any action from client to be authenticated, because flagged as safe.<br /><br />" +
                "Frictionless penetration measures the incidence of frictionless transactions on total ones, and which of them are flagged as safe by the Acquirer."
              }
            />
            <McDonutChartDouble
              data={dataFrictionless}
              sideTextRows={sideTextRowsFrictionless}
              tooltipMeasureUnit="%"
            />
          </>
        }
        rightContent={
          <>
            <SmallTitleFlex
              title={"Challenge success"}
              tooltip={
                "Challenge transactions request an action from client to be authenticated, via Strong Customer Authentication (SCA).<br /><br />" +
                "Challenge success rate measures the number of successful challenge authentication transactions on total number of challenged transactions."
              }
            />
            <div style={{ marginTop: "4rem" }}>
              <McProgressChartBlock
                data={dataChallengeSuccess}
                palette={_variablesTwoOranges}
              />
            </div>
          </>
        }
      />

      {dashboardVersion2 && (
        <>
          <hr style={{ marginBottom: "2rem" }} />
          <div
            className="d-flex justify-content-end"
            style={{ marginBottom: "1rem" }}
          >
            <Download
              section="AutheOverview"
              chartName="3DS Versions"
              data={export3ds2Versions}
              graphType="pie"
              label={"3DS Split"}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              fields={[
                { field: "Transaction Share (%)", value: "trx_share" },
                { field: "Success Rate (%)", value: "success_rate" },
              ]}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitleFlex
                  title={"3DS Versions Share"}
                  tooltip={
                    "The shares of card not present transactions split by 3DS versions"
                  }
                />

                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={data3ds2Versions}
                    palette={_variablesTwoOranges}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <b>Transaction Share</b>
                    <McDonutSideLegend
                      data={data3ds2Versions}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                </div>
              </>
            }
            rightContent={
              <>
                <SmallTitleFlex
                  title={"3DS Versions Success Rate"}
                  tooltip={
                    "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                  }
                />
                <McProgressChartBlock
                  data={data3ds2VersionsBarChart}
                  palette={_variablesTwoOranges}
                />
              </>
            }
          />
        </>
      )}

      <hr />

      <FilterBox>
        <Select
          styleClass="me-3"
          options={selectOptionsFilterOne}
          onChange={handleOnChangeFilterOne}
          placeholder="Total transactions"
        />
        <Select
          options={selectOptionsFilterTwo}
          onChange={handleOnChangeFilterTwo}
          placeholder="Challenge and frictionless"
        />
      </FilterBox>

      {!ukMerchants.includes(merchantName) ? (
        <LayoutTwoColumns
          leftContent={
            <>
              <SmallTitleFlex
                title={"Transaction share per device"}
                tooltip={
                  "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                }
              />
              <McDonutChartSingle
                data={dataTransactionsDevice}
                side={"bars"}
                sideBars={sideBarsTransactionsDevice}
                sideBarsTitle={"Authentication rate"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </>
          }
          rightContent={
            <>
              <div className="d-flex justify-content-between">
                <SmallTitleFlex
                  title={"Transactions count by ticket range"}
                  tooltip={
                    "Percentage of transactions per each ticket range.<br /><br />" +
                    "The green line indicates the Authentication rate per each ticket range calculated as: number of successful authentications divided by total transactions."
                  }
                />
                <Download
                  section="AutheOverview"
                  chartName="Transactions count by ticket range"
                  data={dataTransactionsTicketRange}
                  graphType="barChart"
                  label={"Ticket Range"}
                  field1={"Percentage (%)"}
                  value1={"value"}
                  field2={"Authentication Rate (%)"}
                  value2={"auth_rate"}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
              <McBarChart
                data={dataTransactionsTicketRange}
                dataGuide={{
                  x: "label",
                  barsData: [
                    {
                      dataName: "value",
                      friendlyName: "Percentage of transactions by ticket size",
                    },
                  ],
                  rateData: {
                    dataName: "auth_rate",
                    friendlyName: "Authentication rate",
                  },
                }}
                labels={["Percentage (%)", "Rate (%)"]}
                palette={_variablesThreeOrangesRate}
                tooltipMeasureUnit="%"
                rotateTicks
                labelsPosition="top"
                labelColor="black"
              />
            </>
          }
        />
      ) : (
        <>
          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitleFlex
                  title={"Transaction share per device"}
                  tooltip={
                    "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                  }
                />
                <McDonutChartSingle
                  data={dataTransactionsDevice}
                  side={"bars"}
                  sideBars={sideBarsTransactionsDevice}
                  sideBarsTitle={"Authentication rate"}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
              </>
            }
            // Hide Transactions count by ticket range for UK Merchants
            rightContent={<></>}
          />
        </>
      )}

      <hr />

      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Failure Reasons"}
          tooltip={
            "Incidence of each decline reason on total number of failed authentications (card not present channel only).<br /><br />" +
            "Area of each rectangle indicates the magnitude of each decline reason."
          }
        />
        <Download
          section="AutheOverview"
          chartName="FailureReasons"
          data={dataDecline}
          graphType="decline"
          label={"Decline Reason"}
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          fields={[{ field: "Percentage (%)", value: "percent" }]}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      <McTreemapChart data={dataDecline} />

      {dashboardVersion2 && (
        <>
          <hr
            style={{
              marginBottom: "3rem",
            }}
          />
          <div
            className="d-flex justify-content-end"
            style={{
              marginBottom: "1rem",
            }}
          >
            <Download
              section="AutheOverview"
              chartName="AuthMethods"
              data={exportAuthMethods}
              graphType="pie"
              label={"Auth Method"}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              fields={[
                { field: "Transaction Share (%)", value: "trx_share" },
                { field: "Authentication Rate (%)", value: "success_rate" },
              ]}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitleFlex
                  title={"Transaction share of authentication methods"}
                  tooltip={
                    "Transaction share by different authentication methods"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={dataAuthMethods}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <McDonutSideLegend
                      data={dataAuthMethods}
                      palette={_variablesSix}
                    />
                  </div>
                </div>
              </>
            }
            rightContent={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title={
                      "Authentication Rate for top 5 authentication methods"
                    }
                    tooltip={
                      "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                      "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                    }
                  />
                  <McProgressChartBlock
                    data={dataTopFiveAuthMethods}
                    palette={_variablesSix}
                  />
                  <Button
                    text="Auth method performance by challenge code"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"authMethodModal"}
                  />
                </div>
              </>
            }
          />
        </>
      )}

      {dashboardVersion2 && (
        <Modal
          dataBsTarget="authMethodModal"
          title={<b>Top 5 Authentication Methods by Challenge Code</b>}
          modalBody={
            <div
              style={{
                width: "100%",
                height: "29rem",
                display: "flex",
                gap: "8rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <McDonutChartSingle
                  data={dataChallengeCode}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <b>Transaction Failed due to ACS Timeout</b>
                  <McDonutSideLegend
                    data={dataChallengeCode.map((el) => {
                      return { name: el.label, value: el.value };
                    })}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
              <TableAG
                colDefs={colsDef}
                rowData={dataChallengeCodeForAuthMethod.slice(0, 5)}
              />
            </div>
          }
        >
          <Download
            section="AutheHome"
            chartName="Authentication Methods by Challenge Code"
            data={dataChallengeCodeForAuthMethod}
            graphType="pie"
            label={"Authentication Method"}
            timeRange={
              dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
            }
            fields={[
              { field: "Percent 05", value: "percent_zerofive" },
              { field: "Percent 04", value: "percent_zerofour" },
              { field: "Total", value: "total_trx" },
            ]}
            iconSize="sm"
            className="h-8 w-8"
          />
        </Modal>
      )}
    </LayoutPageContainer>
  );
};

export default AuthenticationHome;
