import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  formatNumberFull,
  formatNumberLocale,
  formatErrorForAlert,
  findMax,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  formatNumberRound,
  declineReasons,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import McBarChart from "../../components/McCharts/McBarChart";
import KpiRow from "../../components/Kpi/KpiRow";
import FilterBox from "../../components/FilterBox/FilterBox";
import FilterBar from "../../components/FilterBox/FilterBar";
import Select from "../../components/Select/Select";
import Kpi from "../../components/Kpi/Kpi";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import SmallTitle from "../../components/Title/SmallTitle";
import {
  _variablesFour,
  _variablesTwelve,
  _variablesThreeOrangesRate,
  _variablesSix,
  _variablesTwoOranges,
} from "../../styles/_variables";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import TableAG from "../../components/Table/TableAG";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import Switcher from "../../components/Switcher/Switcher";
import LayoutFilter from "../../components/Layout/LayoutFilter";
import Download from "../../components/Button/Download";
import { set } from "date-fns";

const colDefs = [
  { field: "exemption", flex: 1 },
  {
    field: "transaction_share",
    headerName: "Transaction Share",
    flex: 1,
  },
  { field: "approval_rate", headerName: "Approval Rate", flex: 1 },
];

const tokenizationFilterOptions = [
  { value: "", text: "All tokenization" },
  { value: "Y", text: "Tokenized" },
  { value: "N", text: "Not tokenized" },
];

const programFilterOptions = [
  { value: "", text: "All programs" },
  { value: "3DS", text: "3DS" },
  { value: "Non 3DS", text: "Non 3DS" },
];

const colsDefMerchantAdviseCode = [
  { field: "label", headerName: "MAC", flex: 1 },
  { field: "value", headerName: "Transaction Share", flex: 1 },
];

const AuthorizationHome = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    authoDates,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  // selects
  const [subchannels, setSubchannels] = useState([]);
  const [selectedSubchannels, setSelectedSubchannels] = useState("0");
  const [selectedSplit, setSelectedSplit] = useState("0");

  // charts
  const [isLoadingPerformance, setIsLoadingPerformance] = useState(true);
  const [dataPerformance, setDataPerformance] = useState([]);
  const [isLoadingTicketAverage, setIsLoadingTicketAverage] = useState(true);
  const [dataTicketAverage, setDataTicketAverage] = useState([]);
  const [isLoadingDeclineReasons, setIsLoadingDeclineReasons] = useState(true);
  const [dataDeclineReasons, setDataDeclineReasons] = useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);
  const [additionalDataPerc, setadditionalDataPerc] = useState([]);
  const [isLoadingTotalTransactions, setIsLoadingTotalTransactions] =
    useState(true);
  const [dataTotalTransactions, setDataTotalTransactions] = useState([]);
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(true);
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [rateBySubchannel, setRateBySubchannel] = useState([]);
  const [isLoadingByTicketRange, setIsLoadingByTicketRange] = useState(true);
  const [dataByTicketRange, setDataByTicketRange] = useState([]);
  const [rateByTicketRange, setRateByTicketRange] = useState([]);
  const [isLoadingByTokenPenetration, setIsLoadingByTokenPenetration] =
    useState(true);
  const [dataByTokenPenetration, setDataByTokenPenetration] = useState([]);
  const [rateByTokenPenetration, setRateByTokenPenetration] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [rateByProgram, setRateByProgram] = useState([]);

  // others
  const [maxDataPerformance, setMaxDataPerformance] = useState(0);
  const [maxDataReasons, setMaxDataReasons] = useState(0);
  // product type
  const [productType, setProductType] = useState("");
  // compliance, exemption types
  const [data3DSCompliance, setData3DSCompliance] = useState([]);
  const [rate3DSCompliance, setRate3DSCompliance] = useState([]);
  const [isLoading3DSCompliance, setIsLoading3DSCompliance] = useState(true);
  const [dataExemptionTypes, setDataExemptionTypes] = useState([]);
  const [isLoadingExemptionTypes, setIsLoadingExemptionTypes] = useState(true);
  // decline reason chart selection
  const [declineReasonChartNumber, setDeclineReasonChartNumber] = useState(1);
  // decline reasons high level view chart
  const [dataDeclineHighLevelView, setDataDeclineHighLevelView] = useState([]);
  const [isLoadingDeclineHighLevel, setIsLoadingDeclineHighLevel] =
    useState(true);
  const [maxHighLevel, setMaxHighLevel] = useState();
  // decline reasons mom trends chart
  const [dataDeclineMomTrends, setDataDeclineMomTrends] = useState([]);
  const [listDeclineMomTrends, setListDeclineMomTrends] = useState();
  const [maxMomTrends, setMaxMomTrends] = useState();
  const [isLoadingDeclineMomTrends, setIsLoadingDeclineMomTrends] =
    useState(true);
  // ticket range filter
  const [ticketRangeOptions, setTicketRangeOptions] = useState([]);
  const [selectedTicketRange, setSelectedTicketRange] = useState("");
  // tokenization filter
  const [tokenizationOptions, setTokenizationOptions] = useState(
    tokenizationFilterOptions
  );
  const [selectedTokenization, setSelectedTokenization] = useState("");
  // program filter
  const [programOptions, setProgramOptions] = useState(programFilterOptions);
  const [selectedProgram, setSelectedProgram] = useState("");

  // merchant advise code
  const [dataMerchantAdviseCodeDonut, setDataMerchantAdviseCodeDonut] =
    useState([]);
  const [
    isLoadingMerchantAdviseCodeDonut,
    setIsLoadingMerchantAdviseCodeDonut,
  ] = useState(true);
  const [dataMerchantAdviseCode, setDataMerchantAdviseCode] = useState([]);
  const [isLoadingMerchantAdviseCode, setIsLoadingMerchantAdviseCode] =
    useState(true);
  // transaction type
  const [transactionType, setTransactionType] = useState("count");
  //export
  const [exportDataBySubchannel, setExportDataBySubchannel] = useState([]);
  const [exportDataByTicketRange, setExportDataByTicketRange] = useState([]);
  const [exportdata3DSCompliance, setExportData3DSCompliance] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (authoDates) {
      setDateLabels(authoDates);
    } else {
      setAlert({
        message: "Authorization dates not available",
        messageType: "error",
      });
    }
  }, [authoDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll("timePeriod");

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );

        getPerformance(body);
        getTicketAverage(body);
        getTicketRangeOptions(body);
        get3DSCompliance(body);
        getExemptionTypes(body);

        let declineBody = assembleBodyDeclineFilter(body);

        getDeclineReasons(declineBody);
        getDeclineReasonsHighLevel(declineBody);
        getDeclineReasonsMomTrends(declineBody);
        getMerchantAdviseCode(body);
        getMerchantAdviseCodeDonut(body);
        // others api are based on filter too
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data based on timeperiod
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyWithProductType(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        productType,
        transactionType === "count" ? false : true
      );

      getPerformance(body);
    }
  }, [transactionType]);

  // get all 'by' charts on change filter or product type filter
  useEffect(() => {
    isLoadingAll("filter");

    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );
        if (selectedSplit !== 0 && selectedSplit !== "0") {
          body.flg_ok_ko = selectedSplit;
        }

        getTotalTransactions(body);
        getBySubchannel(body);
        getByTicketRange(body);
        get3DSCompliance(body);
        getExemptionTypes(body);
        getByTokenPenetration(body);
        getByProgram(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, selectedSplit, productType]);

  // get decline reasons on change filter
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (subchannels.length > 0) {
        let initialBody = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType
        );

        let body = assembleBodyDeclineFilter(initialBody);

        getDeclineReasons(body);
        getDeclineReasonsHighLevel(body);
        getDeclineReasonsMomTrends(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [
    timePeriod,
    dateLabels,
    selectedSubchannels,
    productType,
    selectedProgram,
    selectedTicketRange,
    selectedTokenization,
  ]);

  // get ticket range options based on selected subchannel
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );

        if (selectedSubchannels !== 0 && selectedSubchannels !== "0") {
          body.subchannel = [selectedSubchannels];
        }

        getTicketRangeOptions(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [
    timePeriod,
    dateLabels,
    selectedSplit,
    productType,
    selectedSubchannels,
    transactionType,
  ]);

  const assembleBodyDeclineFilter = (body) => {
    body.ticket_range = selectedTicketRange;
    body.flg_tokenization = selectedTokenization;
    body.program = selectedProgram;

    if (selectedSubchannels !== 0 && selectedSubchannels !== "0") {
      body.subchannel = [selectedSubchannels];
    }

    return body;
  };

  const isLoadingAll = (reason) => {
    if (reason === "timePeriod") {
      setIsLoadingPerformance(true);
      setIsLoadingTicketAverage(true);
      setIsLoadingDeclineReasons(true);
      setIsLoading3DSCompliance(true);
      setIsLoadingExemptionTypes(true);
      setIsLoadingDeclineHighLevel(true);
      setIsLoadingDeclineMomTrends(true);
      setIsLoadingMerchantAdviseCode(true);
      setIsLoadingMerchantAdviseCodeDonut(true);
    } else if (reason === "filter") {
      setIsLoadingTotalTransactions(true);
      setIsLoadingBySubchannel(true);
      setIsLoadingByTicketRange(true);
      setIsLoadingByTokenPenetration(true);
      setIsLoadingByProgram(true);
    }
  };

  const getPerformance = (body) => {
    setIsLoadingPerformance(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/approval-rate-performance",
      body
    )
      .then((res) => {
        res.data.forEach((el) => {
          el.ok = el.ok ? el.ok / 1000 : 0;
          el.ko = el.ko ? el.ko / 1000 : 0;
          el.total = el.total ? el.total * 100 : 0;
        });
        setDataPerformance(res.data);

        // find max for better chart visualization
        setMaxDataPerformance(findMax(res.data));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingPerformance(false));
  };

  const getTicketAverage = (body) => {
    setIsLoadingTicketAverage(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/avg-ticket-approved-denied",
      body
    )
      .then((res) => {
        let temp = [];
        temp.push(
          formatNumberFull(res.data.find((el) => el.label === "Y").value)
        );
        temp.push(
          formatNumberFull(res.data.find((el) => el.label === "N").value)
        );
        setDataTicketAverage(temp);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTicketAverage(false));
  };

  const getTicketRangeOptions = (body) => {
    FetchService.post(
      "/api/dashboard/autho/approval-trends/get-ticket-range",
      body
    )
      .then((res) => {
        let tempData = [{ value: "", text: "All tickets" }];
        tempData = [
          ...tempData,
          ...res.data.map((el) => {
            return {
              value: el.ticket_range ? el.ticket_range : "",
              text: el.ticket_range ? "€" + el.ticket_range : "",
            };
          }),
        ];
        setTicketRangeOptions(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const getDeclineReasons = (body) => {
    setIsLoadingDeclineReasons(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/decline-reasons",
      body
    )
      .then((res) => {
        // simply take the first month reasons
        /* if (res.data.length > 0) {
          setListDeclineReasons(
            res.data[0].decline_reason_count.map((el) => el.label)
          );
        } */
        let tempReasons = [];
        res.data.forEach((month) => {
          month?.decline_reason_count?.forEach((reason) => {
            if (!tempReasons.includes(reason.label)) {
              tempReasons.push(reason.label);
            }
          });
        });
        setListDeclineReasons(tempReasons.sort((a, b) => a.localeCompare(b)));

        let tempPerc = [];
        let tempData = res.data.map((el, index) => {
          let bar = {},
            perc = {};
          bar.month = el.yearmonth ? el.yearmonth : null;
          bar.index = index;
          el.decline_reason_count?.forEach(
            (prop) => (bar[prop.label] = prop.value / 1000)
          );

          el.decline_reason_count?.forEach(
            (prop) => (perc[prop.label] = prop.percent)
          );
          tempPerc.push(perc);

          return bar;
        });

        setDataDeclineReasons(tempData);
        setadditionalDataPerc(tempPerc);

        // find max for better chart visualization
        setMaxDataReasons(findMax(tempData));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineReasons(false));
  };

  const getDeclineReasonsHighLevel = (body) => {
    setIsLoadingDeclineHighLevel(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/high-level-view",
      body
    )
      .then((res) => {
        let sortData = [];
        if (res.data.length > 0) {
          sortData = res.data.sort((a, b) => b.percent - a.percent);
          // Aggiungi qui la mappatura per modificare il label
          sortData = sortData.map((item) => {
            if (item.label === "Technical or Validation Declines") {
              return { ...item, label: "Technical Declines" };
            }
            return item;
          });
        }
        setDataDeclineHighLevelView(sortData);
        setMaxHighLevel(sortData.length > 0 ? findMax(sortData) : sortData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineHighLevel(false));
  };

  const getDeclineReasonsMomTrends = (body) => {
    setIsLoadingDeclineMomTrends(true);
    FetchService.post("/api/dashboard/autho/approval-trends/mom-trends", body)
      .then((res) => {
        let tempReasons = [];
        res.data.forEach((month) => {
          month.decline_reason_count?.forEach((reason) => {
            if (!tempReasons.includes(reason.label)) {
              tempReasons.push(reason.label);
            }
          });
        });
        setListDeclineMomTrends(tempReasons.sort((a, b) => a.localeCompare(b)));
        let tempPerc = [];
        let tempData = res.data.map((el, index) => {
          let bar = {},
            perc = {};
          bar.month = el.yearmonth ? el.yearmonth : null;
          bar.index = index;
          el.decline_reason_count?.forEach(
            (prop) => (bar[prop.label] = prop.percent)
          );

          el.decline_reason_count?.forEach(
            (prop) => (perc[prop.label] = prop.percent)
          );
          tempPerc.push(perc);

          return bar;
        });

        setDataDeclineMomTrends(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineMomTrends(false));
  };

  const getTotalTransactions = (body) => {
    setIsLoadingTotalTransactions(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/total-transactions",
      body
    )
      .then((res) => {
        setDataTotalTransactions(res.data.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTotalTransactions(false));
  };

  const getBySubchannel = (body) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/transactions-by-subchannel-and-approval-rate",
      body
    )
      .then((res) => {
        setExportDataBySubchannel(res.data);
        let cleanRes = res.data.filter((el) => el.label !== "");
        cleanRes.sort((a, b) => a.label.localeCompare(b.label));

        let tempSubchannels = cleanRes.map((el) => {
          return { value: el.label.toUpperCase(), text: el.label };
        });
        tempSubchannels = [
          { value: 0, text: "All subchannels" },
          ...tempSubchannels,
        ];
        setSubchannels(tempSubchannels);

        setDataBySubchannel(
          sortSubchannelsList(
            cleanRes.map((el) => {
              return { name: el.label, value: el.value };
            })
          )
        );
        setRateBySubchannel(
          sortSubchannelsList(
            cleanRes.map((el) => {
              return {
                name: el.label,
                value: el.approval_rate,
              };
            })
          )
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getByTicketRange = (body) => {
    setIsLoadingByTicketRange(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/transactions-by-ticket-range-and-approval-rate",
      body
    )
      .then((res) => {
        setExportDataByTicketRange(res.data);
        setDataByTicketRange(
          sortTicketRanges(
            res.data.map((el) => {
              return {
                name: el.label ? el.label + " €" : "",
                value: el.value ? el.value : 0,
              };
            }),
            "€"
          )
        );
        setRateByTicketRange(
          sortTicketRanges(
            res.data.map((el) => {
              return {
                name: el.label + " €",
                value: el.approval_rate,
              };
            }),
            "€"
          )
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicketRange(false));
  };

  const getByTokenPenetration = (body) => {
    setIsLoadingByTokenPenetration(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/transactions-by-token-penetration-and-approval-rate",
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label < b.label ? 1 : a.label > b.label ? -1 : 0
        );

        setDataByTokenPenetration(
          cleanRes.map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.value ? el.value : 0,
            };
          })
        );
        setRateByTokenPenetration(
          cleanRes.map((el) => {
            return {
              name: el.label,
              value: el.approval_rate,
            };
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTokenPenetration(false));
  };

  const getByProgram = (body) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      "/api/dashboard/autho/approval-trends/transactions-by-program-and-approval-rate",
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        setDataByProgram(
          cleanRes.map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.value ? el.value : 0,
            };
          })
        );
        setRateByProgram(
          cleanRes.map((el) => {
            return {
              name: el.label,
              value: el.approval_rate,
            };
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const get3DSCompliance = (body) => {
    setIsLoading3DSCompliance(true);
    FetchService.post(
      "/api/dashboard/autho/approval-trends/3DS-compliance",
      body
    )
      .then((res) => {
        setExportData3DSCompliance(res.data);
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        setData3DSCompliance(
          cleanRes.map((el) => {
            return { name: el.label, value: el.trx_share };
          })
        );
        setRate3DSCompliance(
          cleanRes.map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.approval_rate ? el.approval_rate : 0,
            };
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3DSCompliance(false));
  };

  const getExemptionTypes = (body) => {
    setIsLoadingExemptionTypes(true);
    FetchService.post(
      "/api/dashboard/autho/approval-trends/exemption-types",
      body
    )
      .then((res) => {
        let rowData = res.data.map((el) => {
          return {
            exemption: el.label,
            transaction_share:
              formatNumberRound(Number(el.trx_share ? el.trx_share : 0)) + "%",
            approval_rate:
              formatNumberRound(
                Number(el.approval_rate ? el.approval_rate : 0)
              ) + "%",
          };
        });

        setDataExemptionTypes(rowData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingExemptionTypes(false));
  };

  // donut data
  const getMerchantAdviseCodeDonut = (body) => {
    setIsLoadingMerchantAdviseCodeDonut(true);
    FetchService.post(
      "/api/dashboard/autho/approval-trends/merchant-advice-code-total-percentage",
      body
    )
      .then((res) => {
        let data = [];
        data.push(
          {
            name: "Mac Present",
            value: res.data.mac_present ? res.data.mac_present : 0,
          },
          {
            name: "Mac Absent",
            value: res.data.mac_absent ? res.data.mac_absent : 0,
          }
        );

        setDataMerchantAdviseCodeDonut(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCodeDonut(false));
  };

  // table data
  const getMerchantAdviseCode = (body) => {
    setIsLoadingMerchantAdviseCode(true);
    FetchService.post(
      "/api/dashboard/autho/approval-trends/merchant-advice-code",
      body
    )
      .then((res) => {
        setDataMerchantAdviseCode(
          res.data.map((el) => {
            return {
              label: el.label ? el.label : "",
              value: formatNumberRound(el.percent ? el.percent : 0) + "%",
            };
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCode(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  const handleSelectedTicketRange = (e) => {
    setSelectedTicketRange(e);
  };

  const handleSelectedTokenization = (e) => {
    setSelectedTokenization(e);
  };

  const handleSelectedProgram = (e) => {
    setSelectedProgram(e);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authorization/home",
      active: true,
    },
    { title: "Geography", link: "/authorization/geography" },
    { title: "Benchmark", link: "/authorization/benchmark" },
    { title: "Acquirers", link: "/authorization/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authorization/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  useEffect(() => {
    if (dashboardVersion1) {
      setDeclineReasonChartNumber(3);
    }
  }, [dashboardVersion1]);

  return (
    <LayoutPageContainer
      title={"Authorization"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingPerformance,
          isLoadingTicketAverage,
          isLoadingDeclineReasons,
          isLoadingDeclineHighLevel,
          isLoadingDeclineMomTrends,
          isLoadingTotalTransactions,
          isLoadingBySubchannel,
          isLoadingByTicketRange,
          isLoadingByTokenPenetration,
          isLoadingByProgram,
          isLoading3DSCompliance,
          isLoadingExemptionTypes,
          isLoadingMerchantAdviseCode,
          isLoadingMerchantAdviseCodeDonut,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}

      <hr />
      {/*  <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Approval performance"}
          tooltip={
            "Number of monthly card not present transactions split by authorization outcome (approved or declined).<br /><br />" +
            "The green line indicates the Approval rate calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket calculated as: volumes (spend) divided by transactions."
          }
        />
        <Download
          section="AuthoOverview"
          chartName="Approval performance"
          data={dataPerformance}
          graphType="barChart"
          productType={productType}
          label={"Month"}
          field1={
            transactionType === "count"
              ? "Approved transactions count"
              : "Approved transactions volume"
          }
          value1={"ok"}
          field2={
            transactionType === "count"
              ? "Declined transactions count"
              : "Declined transactions volume"
          }
          value2={"ko"}
          field3={"Approval rate (%)"}
          value3={"total"}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      />
      <McBarChart
        data={dataPerformance}
        dataGuide={{
          x: "yearmonth",
          barsData: [
            { dataName: "ok", friendlyName: "Approved transactions" },
            { dataName: "ko", friendlyName: "Declined transactions" },
          ],
          rateData: {
            dataName: "total",
            friendlyName: "Approval rate",
          },
        }}
        labels={[
          transactionType === "count" ? "Transactions (K)" : "Volumes (K€)",
          "Rate (%)",
        ]}
        palette={_variablesThreeOrangesRate}
        maxData={maxDataPerformance}
        tooltipMeasureUnit={transactionType === "count" ? " K" : " K€"}
      />

      <div className="mt-5">
        <KpiRow
          offset
          kpis={[
            {
              leftContent:
                formatNumberLocale(
                  dataTicketAverage[0] ? dataTicketAverage[0] : "0"
                ) + " €",
              righContent: (
                <div>
                  <b>Average ticket</b>
                  <br />
                  Approved transaction
                </div>
              ),
            },
            {
              leftContent:
                formatNumberLocale(
                  dataTicketAverage[0] ? dataTicketAverage[1] : "0"
                ) + " €",
              righContent: (
                <div>
                  <b>Average ticket</b>
                  <br />
                  Declined transaction
                </div>
              ),
              color: _variablesThreeOrangesRate.primaryOrangeLight,
            },
          ]}
        />
      </div>

      <hr />
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Decline reasons"}
          tooltip={
            "Monthly number of card not present declined transactions by decline reason."
          }
        />
        {declineReasonChartNumber === 1 && (
          <Download
            section="AuthoOverview"
            chartName="Decline reasons - HLV"
            data={dataDeclineHighLevelView}
            graphType="declineReasons"
            productType={productType}
            subchannels={selectedSubchannels}
            ticketRange={selectedTicketRange}
            tokenization={selectedTokenization}
            program={selectedProgram}
            timeRange={
              dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
            }
            label={"Decline Reason"}
            fields={[
              { field: "Count", value: "value" },
              { field: "Percent (%)", value: "percent" },
            ]}
            iconSize="sm"
            className="h-8 w-8"
          />
        )}
        {declineReasonChartNumber === 2 && (
          <Download
            section="AuthoOverview"
            chartName="Decline reasons MOM"
            data={dataDeclineMomTrends}
            graphType="declineReasons"
            productType={productType}
            subchannels={selectedSubchannels}
            ticketRange={selectedTicketRange}
            tokenization={selectedTokenization}
            program={selectedProgram}
            timeRange={
              dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
            }
            label={"Month"}
            fields={[
              "Financial Declines (%)",
              "Policy Declines (%)",
              "Invalid Transaction Declines (%)",
              "Security Declines (%)",
              "Soft Declines (%)",
              "Other Declines (%)",
            ].map((field) => ({
              field,
              value: field.replace(" (%)", ""),
            }))}
            iconSize="sm"
            className="h-8 w-8"
          />
        )}
        {declineReasonChartNumber === 3 && (
          <Download
            section="AuthoOverview"
            chartName="Decline reasons - DeepDive"
            data={dataDeclineReasons}
            graphType="declineReasons"
            productType={productType}
            subchannels={selectedSubchannels}
            ticketRange={selectedTicketRange}
            tokenization={selectedTokenization}
            program={selectedProgram}
            timeRange={
              dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
            }
            label={"Month"}
            fields={listDeclineReasons.map((field) => ({
              field,
              value: field,
            }))}
            iconSize="sm"
            className="h-8 w-8"
          />
        )}
      </div>

      <FilterBar>
        <b>Filters</b>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className="flex-xl-row gap-2"
        >
          <Select
            options={subchannels}
            onChange={setSelectedSubchannels}
            placeholder="All subchannels"
          />
          <LayoutFilter
            filterName={""}
            filterPlaceholder={"All tickets"}
            options={ticketRangeOptions}
            onChange={handleSelectedTicketRange}
          />
          <LayoutFilter
            filterName={""}
            filterPlaceholder={"All tokenization"}
            options={tokenizationOptions}
            onChange={handleSelectedTokenization}
          />
          <LayoutFilter
            filterName={""}
            filterPlaceholder={"All programs (3DS vs Non-3DS)"}
            options={programOptions}
            onChange={handleSelectedProgram}
          />
        </div>
        <Button
          style={{ marginLeft: "auto" }}
          text={"Glossary"}
          dataBsToggle="modal"
          dataBsTarget={"glossaryModal"}
        />
      </FilterBar>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "3rem 0 1rem 0",
        }}
      >
        {dashboardVersion2 && (
          <Switcher
            pages={[
              {
                title: "High Level View",
                onClick: () => {
                  setDeclineReasonChartNumber(1);
                },
              },
              {
                title: "M-O-M Trends",
                onClick: () => {
                  setDeclineReasonChartNumber(2);
                },
              },
              {
                title: "Deep Dive",
                onClick: () => {
                  setDeclineReasonChartNumber(3);
                },
              },
            ]}
          />
        )}
      </div>
      <Modal
        title="Decline Reasons Glossary"
        dataBsTarget={"glossaryModal"}
        modalBody={
          <div>
            <p>Explanation of each decline reason:</p>
            <ul>
              {listDeclineReasons.map((reason, index) => (
                <li key={index}>
                  <strong>{reason}:</strong> {declineReasons[reason]}
                </li>
              ))}
            </ul>
          </div>
        }
      />
      {declineReasonChartNumber === 1 && (
        <McBarChart
          domain={[0, 100]}
          data={dataDeclineHighLevelView}
          dataGuide={{
            x: "label",
            barsData: [{ dataName: "percent", friendlyName: "Percentage" }],
          }}
          labels={["Decline Categories (%)"]}
          twoYs={false}
          maxData={maxHighLevel}
          className={"text-sm"}
        />
      )}
      {declineReasonChartNumber === 2 && (
        <McBarChart
          domain={[0, 100]}
          data={dataDeclineMomTrends}
          dataGuide={{
            x: "month",
            barsData: listDeclineMomTrends.map((el) => {
              return { dataName: el, friendlyName: el };
            }),
          }}
          labels={[]}
          maxData={maxMomTrends}
          twoYs={false}
        />
      )}
      {declineReasonChartNumber === 3 && (
        <McBarChart
          data={dataDeclineReasons}
          dataGuide={{
            x: "month",
            barsData: listDeclineReasons.map((el) => {
              return { dataName: el, friendlyName: el };
            }),
          }}
          palette={_variablesTwelve}
          maxData={maxDataReasons}
          tooltipMeasureUnit=" K"
          additionalData={additionalDataPerc}
          twoYs={false}
        />
      )}

      <hr />

      <SmallTitle>Transactions split</SmallTitle>
      <FilterBar>
        <b>Filters</b>

        <Select
          options={[
            { value: 0, text: "Total transactions" },
            { value: "Y", text: "Approved transactions" },
            { value: "N", text: "Declined transactions" },
          ]}
          onChange={setSelectedSplit}
          placeholder="Total transactions"
        />
      </FilterBar>
      <div className="row mb-3 mt-3">
        <div
          className={
            "col-12 " +
            (dataTotalTransactions > 10000000 ? "col-lg-6" : "col-lg-4")
          }
        >
          <Kpi
            leftContent={formatNumberFull(dataTotalTransactions / 1000) + " K"}
            rightContent={"Transactions"}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-end"
        style={{ marginBottom: "1rem" }}
      >
        <Download
          section="AuthoOverview"
          chartName="Subchannel"
          data={exportDataBySubchannel}
          graphType="pie"
          label={"Subchannel"}
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          fields={[
            { field: "Transaction Share (%)", value: "value" },
            { field: "Approval Rate (%)", value: "approval_rate" },
          ]}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>

      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitleFlex
              title={"Transactions by subchannel"}
              tooltip={
                "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
              }
            />
            <McDonutChartSingle
              data={dataBySubchannel}
              side={"legend"}
              palette={_variablesFour}
              tooltipMeasureUnit="%"
            />
          </>
        }
        rightContent={
          <>
            <SmallTitleFlex
              title="Approval rate by subchannel"
              tooltip={
                "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
              }
            ></SmallTitleFlex>
            <McProgressChartBlock
              data={rateBySubchannel}
              palette={_variablesFour}
            />
          </>
        }
      />

      <hr style={{ marginBottom: "2rem" }} />

      <div
        className="d-flex justify-content-end"
        style={{ marginBottom: "1rem" }}
      >
        <Download
          section="AuthoOverview"
          chartName="TicketRange"
          data={exportDataByTicketRange}
          graphType="pie"
          label={"Ticket Range"}
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          fields={[
            { field: "Transaction Share (%)", value: "value" },
            { field: "Approval Rate (%)", value: "approval_rate" },
          ]}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitleFlex
              title={"Transaction count by ticket range"}
              tooltip={
                "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
              }
            />
            <McDonutChartSingle
              data={dataByTicketRange}
              side={"legend"}
              palette={_variablesSix}
              tooltipMeasureUnit="%"
            />
          </>
        }
        rightContent={
          <>
            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={rateByTicketRange}
              palette={_variablesSix}
            />
          </>
        }
      />

      <hr />

      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitleFlex
              title={"Transactions by token penetration"}
              tooltip={
                "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
              }
            />
            <McDonutChartSingle
              data={dataByTokenPenetration}
              side={"legend"}
              palette={_variablesTwoOranges}
              tooltipMeasureUnit="%"
            />
          </>
        }
        rightContent={
          <>
            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={rateByTokenPenetration}
              palette={_variablesTwoOranges}
            />
          </>
        }
      />

      <hr />

      <LayoutTwoColumns
        leftContent={
          <>
            <SmallTitleFlex
              title={"Transactions by program"}
              tooltip={
                "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
              }
            />
            <McDonutChartSingle
              data={dataByProgram}
              side={"legend"}
              palette={_variablesTwoOranges}
              tooltipMeasureUnit="%"
            />
          </>
        }
        rightContent={
          <>
            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={rateByProgram}
              palette={_variablesTwoOranges}
            />
          </>
        }
      />

      {dashboardVersion2 && (
        <>
          <hr />

          <div
            className="d-flex justify-content-end"
            style={{ marginBottom: "2rem" }}
          >
            <Download
              section="AuthoOverview"
              chartName="3DS Exemptions"
              data={exportdata3DSCompliance}
              graphType="pie"
              label={"3DS Exemptions"}
              timeRange={
                dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
              }
              fields={[
                { field: "Transaction Share (%)", value: "trx_share" },
                { field: "Approval Rate (%)", value: "approval_rate" },
              ]}
              iconSize="sm"
              className="h-8 w-8"
            />
          </div>
          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitleFlex
                  title={"3DS Exemptions Share"}
                  tooltip={
                    "Transaction share of various CNP compositions along with exemptions."
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={data3DSCompliance}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <b>Transaction Share</b>
                    <McDonutSideLegend
                      data={data3DSCompliance}
                      palette={_variablesSix}
                    />
                  </div>
                </div>

                <Modal
                  dataBsTarget="exemptionsModal"
                  title={<b>Non-3DS Exemption Types</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG colDefs={colDefs} rowData={dataExemptionTypes} />
                    </div>
                  }
                >
                  <Download
                    section="AuthoOverview"
                    chartName="Non-3DS Exemption Types"
                    data={dataExemptionTypes}
                    graphType="pie"
                    label={"Exemption Type"}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[
                      {
                        field: "Transaction Share",
                        value: "transaction_share",
                      },
                      { field: "Approval Rate", value: "approval_rate" },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
              </>
            }
            rightContent={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title="Approval Rate by 3DS exemptions"
                    tooltip={
                      "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                      "Approval rate is defined as number of successfully approved transactions over total transactions"
                    }
                  />
                  <McProgressChartBlock
                    data={rate3DSCompliance}
                    palette={_variablesSix}
                  />
                  <Button
                    text="Exemptions Details"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"exemptionsModal"}
                  />
                </div>
              </>
            }
          />
        </>
      )}

      {dashboardVersion2 && (
        <>
          <hr />

          <LayoutTwoColumns
            leftContent={
              <>
                <SmallTitleFlex
                  title={"Transaction share by merchant advice code"}
                  tooltip={
                    "Share of declined transactions split by MAC Present or Absent status"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={dataMerchantAdviseCodeDonut}
                    palette={_variablesTwoOranges}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <span>
                      <b>Transaction Share </b>
                      (within declined transactions)
                    </span>
                    <McDonutSideLegend
                      data={dataMerchantAdviseCodeDonut.map((el) => {
                        return { name: el.name, value: el.value };
                      })}
                      palette={_variablesTwoOranges}
                    />
                  </div>
                </div>
                <Modal
                  dataBsTarget="authMethodModal"
                  title={<b>Deepdive of MAC transaction share</b>}
                  modalBody={
                    <div
                      style={{
                        width: "100%",
                        height: "30rem",
                        display: "flex",
                      }}
                    >
                      <TableAG
                        colDefs={colsDefMerchantAdviseCode}
                        rowData={dataMerchantAdviseCode}
                      />
                    </div>
                  }
                >
                  <Download
                    section="AuthoOverview"
                    chartName="MAC"
                    data={dataMerchantAdviseCode}
                    graphType="pie"
                    label={"MAC"}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    fields={[{ field: "Transaction Share", value: "value" }]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </Modal>
              </>
            }
            rightContent={
              <>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button
                    text="Details"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      // marginTop: "1rem",
                      width: "15rem",
                      // marginLeft: "auto",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"authMethodModal"}
                  />
                </div>
              </>
            }
          />
        </>
      )}
    </LayoutPageContainer>
  );
};

export default AuthorizationHome;
