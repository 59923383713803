import { useState, useEffect, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatErrorForAlert,
  titleCase,
  sortTicketRanges,
  sortSubchannelsList,
  assembleBody,
  assembleBodyWithProductType,
  formatNumberRound,
  compareAndAddDummy,
  mergeData,
  padArray,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import Refreshing from "../../components/Refreshing/Refreshing";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import LayoutGeographyFilter from "../../components/Layout/LayoutGeographyFilter";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import KpiColumn from "../../components/Kpi/KpiColumn";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import McTreemapChart from "../../components/McCharts/McTreemapChart";
import SmallTitle from "../../components/Title/SmallTitle";
import Title from "../../components/Title/Title";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import Button from "../../components/Button/Button";
import {
  _variablesFour,
  _variablesTwelve,
  _variablesThreeOrangesRate,
  _variablesSix,
  _variablesTwoOranges,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import LayoutCardFilter from "../../components/Layout/LayoutCardFilter";
import Switcher from "../../components/Switcher/Switcher";
import Download from "../../components/Button/Download";

const colDefs = [
  { field: "exemption", flex: 1 },
  {
    field: "transaction_share",
    headerName: "Transaction Share",
    flex: 1,
  },
  { field: "approval_rate", headerName: "Approval Rate", flex: 1 },
];

const colsDefMerchantAdviseCode = [
  { field: "label", headerName: "MAC", flex: 1 },
  { field: "value", headerName: "Transaction Share", flex: 1 },
];

const AuthorizationGeography = () => {
  // global
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    dashboardVersion,
    authoDates,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);

  // geography & benchmark
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  // dashboard version
  const dashboardVersion1 = dashboardVersion === 1 ? true : false;
  const dashboardVersion2 = dashboardVersion === 2 ? true : false;

  // charts
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(true);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [dataKpis, setDataKpis] = useState([]);
  const [dataKpisAvg, setDataKpisAvg] = useState([]);
  const [isLoadingBySubchannel, setIsLoadingBySubchannel] = useState(true);
  const [dataBySubchannel, setDataBySubchannel] = useState([]);
  const [rateBySubchannel, setRateBySubchannel] = useState([]);
  const [isLoadingByTicketRange, setIsLoadingByTicketRange] = useState(true);
  const [dataByTicketRange, setDataByTicketRange] = useState([]);
  const [rateByTicketRange, setRateByTicketRange] = useState([]);
  const [isLoadingByTokenPenetration, setIsLoadingByTokenPenetration] =
    useState(true);
  const [dataByTokenPenetration, setDataByTokenPenetration] = useState([]);
  const [rateByTokenPenetration, setRateByTokenPenetration] = useState([]);
  const [isLoadingByProgram, setIsLoadingByProgram] = useState(true);
  const [dataByProgram, setDataByProgram] = useState([]);
  const [rateByProgram, setRateByProgram] = useState([]);
  const [isLoadingDeclineReasons, setIsLoadingDeclineReasons] = useState(true);
  const [dataDeclineReasons, setDataDeclineReasons] = useState([]);

  // charts countries
  const [dataKpisCountries, setDataKpisCountries] = useState([]);
  const [dataBySubchannelCountries, setDataBySubchannelCountries] = useState(
    []
  );
  const [rateBySubchannelCountries, setRateBySubchannelCountries] = useState(
    []
  );
  const [dataByTicketRangeCountries, setDataByTicketRangeCountries] = useState(
    []
  );
  const [rateByTicketRangeCountries, setRateByTicketRangeCountries] = useState(
    []
  );
  const [dataByTokenPenetrationCountries, setDataByTokenPenetrationCountries] =
    useState([]);
  const [rateByTokenPenetrationCountries, setRateByTokenPenetrationCountries] =
    useState([]);
  const [dataByProgramCountries, setDataByProgramCountries] = useState([]);
  const [rateByProgramCountries, setRateByProgramCountries] = useState([]);
  const [data3DSCompliance, setData3DSCompliance] = useState([]);
  const [rate3DSCompliance, setRate3DSCompliance] = useState([]);
  const [data3DSComplianceCountries, setData3DSComplianceCountries] = useState(
    []
  );
  const [rate3DSComplianceCountries, setRate3DSComplianceCountries] = useState(
    []
  );
  const [dataExemptionTypes, setDataExemptionTypes] = useState([]);
  const [dataExemptionTypesCountries, setDataExemptionTypesCountries] =
    useState([]);
  const [isLoadingExemptionTypes, setIsLoadingExemptionTypes] = useState(true);
  const [isLoading3DSCompliance, setIsLoading3DSCompliance] = useState(true);
  const [dataDeclineReasonsCountries, setDataDeclineReasonsCountries] =
    useState([]);
  const [listDeclineReasons, setListDeclineReasons] = useState([]);
  // product type
  const [productType, setProductType] = useState("");
  // merchant advise code
  const [dataMerchantAdviseCodeDonut, setDataMerchantAdviseCodeDonut] =
    useState([]);
  const [
    dataMerchantAdviseCodeDonutCountries,
    setDataMerchantAdviseCodeDonutCountries,
  ] = useState([]);
  const [
    isLoadingMerchantAdviseCodeDonut,
    setIsLoadingMerchantAdviseCodeDonut,
  ] = useState(true);
  const [dataMerchantAdviseCode, setDataMerchantAdviseCode] = useState([]);
  const [dataMerchantAdviseCodeCountries, setDataMerchantAdviseCodeCountries] =
    useState([]);

  const [isLoadingMerchantAdviseCode, setIsLoadingMerchantAdviseCode] =
    useState(true);

  // transaction type
  const [transactionType, setTransactionType] = useState("count");
  //api response
  const [apiDataSubchannel, setApiDataSubchannel] = useState([]);
  const [apiDataSubchannelCountries, setApiDataSubchannelCountries] = useState(
    []
  );
  const [apiDataTicketRange, setApiDataTicketRange] = useState([]);
  const [apiDataTicketRangeCountries, setApiDataTicketRangeCountries] =
    useState([]);
  const [apiData3DSCompliance, setApiData3DSCompliance] = useState([]);
  const [apiData3DSComplianceCountries, setApiData3DSComplianceCountries] =
    useState([]);
  //merged data
  const [mergedDataSubchannel, setMergedDataSubchannel] = useState([]);
  const [mergedDataTicketRange, setMergedDataTicketRange] = useState([]);
  const [mergedData3DSCompliance, setMergedData3DSCompliance] = useState([]);
  const [mergedDataDeclineReasons, setMergedDataDeclineReasons] = useState([]);

  // get dates for timeperiod
  useEffect(() => {
    if (authoDates) {
      setDateLabels(authoDates);
    } else {
      setAlert({
        message: "Authorization dates not available",
        messageType: "error",
      });
    }
  }, [authoDates]);

  // get charts data based on timeperiod
  useEffect(() => {
    isLoadingAll();

    // wait some time for other changes
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBodyWithProductType(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          acquirerName,
          productType,
          transactionType === "count" ? false : true
        );

        // get countries for select
        getCountries(body);
        getTopFifteen(body);
        getKpis(body);
        getKpisAvg(body);
        getBySubchannel(body);
        getByTicketRange(body);
        getByTokenPenetration(body);
        getByProgram(body);
        get3DSCompliance(body);
        getExemptionTypes(body);
        getDeclineReasons(body);
        getMerchantAdviseCode(body);
        getMerchantAdviseCodeDonut(body);
      }
    }, 1500);

    return () => clearTimeout(timeOut);
  }, [timePeriod, dateLabels, productType]);

  // get charts data
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyWithProductType(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        acquirerName,
        productType,
        transactionType === "count" ? false : true
      );

      getTopFifteen(body);
      getKpisAvg(body);
    }
  }, [transactionType]);

  // get charts data based on country
  useEffect(() => {
    if (selectedCountries.length > 0) {
      let body = assembleBodyCountry();

      getKpis(body, true);
      getBySubchannel(body, true);
      getByTicketRange(body, true);
      getByTokenPenetration(body, true);
      getByProgram(body, true);
      get3DSCompliance(body, true);
      getExemptionTypes(body, true);
      getDeclineReasons(body, true);
      getMerchantAdviseCode(body, true);
      getMerchantAdviseCodeDonut(body, true);
    } else {
      clearChartsCountries();
    }
  }, [timePeriod, dateLabels, selectedCountries, productType]);

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
    setIsLoadingKpis(true);
    setIsLoadingBySubchannel(true);
    setIsLoadingByTicketRange(true);
    setIsLoadingByTokenPenetration(true);
    setIsLoadingByProgram(true);
    setIsLoading3DSCompliance(true);
    setIsLoadingExemptionTypes(true);
    setIsLoadingDeclineReasons(true);
    setIsLoadingMerchantAdviseCodeDonut(true);
    setIsLoadingMerchantAdviseCode(true);
  };

  const assembleBodyCountry = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      country: selectedCountries.map((el) => el.value),
      product_type: productType,
    };
  };

  const handleSelectCountry = (e) => {
    setSelectedCountries(e);
  };

  const getCountries = (body) => {
    FetchService.post("/api/dashboard/geography/autho/top", body)
      .then((res) => {
        let tempCountries = res.data.countries?.sort((a, b) =>
          a.localeCompare(b)
        );
        tempCountries = tempCountries.map((el) => {
          return { value: el, label: titleCase(el) };
        });

        setCountries(tempCountries);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  const clearChartsCountries = () => {
    setDataKpisCountries([]);
    setDataBySubchannelCountries([]);
    setRateBySubchannelCountries([]);
    setDataByTicketRangeCountries([]);
    setRateByTicketRangeCountries([]);
    setDataByTokenPenetrationCountries([]);
    setRateByTokenPenetrationCountries([]);
    setDataByProgramCountries([]);
    setRateByProgramCountries([]);
    setDataDeclineReasonsCountries([]);
    setData3DSComplianceCountries([]);
    setRate3DSComplianceCountries([]);
    setDataExemptionTypesCountries([]);
    setDataMerchantAdviseCodeCountries([]);
    setDataMerchantAdviseCodeDonutCountries([]);
  };

  const getTopFifteen = (body) => {
    setIsLoadingTopFifteen(true);

    FetchService.post(
      "/api/dashboard/autho/geography/top-15-countries-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            country: titleCase(el.country),
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            country: titleCase(el.country),
            value: el.auth_rate ? el.auth_rate : 0,
            avg: el.above_average ? el.above_average : 0,
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getKpis = (body, isCountries) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/autho/geography/performance", body)
      .then((res) => {
        isCountries ? setDataKpisCountries(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);

    FetchService.post("/api/dashboard/autho/geography/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getBySubchannel = (body, isCountries) => {
    setIsLoadingBySubchannel(true);

    FetchService.post(
      "/api/dashboard/autho/geography/transactions-by-subchannel-and-approval-rate",
      body
    )
      .then((res) => {
        isCountries
          ? setApiDataSubchannelCountries(res.data)
          : setApiDataSubchannel(res.data);
        let cleanRes = res.data.filter((el) => el.label !== "");
        cleanRes.sort((a, b) => a.label.localeCompare(b.label));

        let tempData = cleanRes.map((el) => {
          return { name: el.label, value: el.value };
        });
        sortSubchannelsList(tempData);
        isCountries
          ? setDataBySubchannelCountries(tempData)
          : setDataBySubchannel(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label,
            value: el.approval_rate,
          };
        });
        sortSubchannelsList(tempRate);
        isCountries
          ? setRateBySubchannelCountries(tempRate)
          : setRateBySubchannel(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingBySubchannel(false));
  };

  const getByTicketRange = (body, isCountries) => {
    setIsLoadingByTicketRange(true);

    FetchService.post(
      "/api/dashboard/autho/geography/transactions-by-ticket-range-and-approval-rate",
      body
    )
      .then((res) => {
        isCountries
          ? setApiDataTicketRangeCountries(res.data)
          : setApiDataTicketRange(res.data);
        let tempData = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.value ? el.value : 0,
          };
        });
        isCountries
          ? setDataByTicketRangeCountries(sortTicketRanges(tempData, "€"))
          : setDataByTicketRange(sortTicketRanges(tempData, "€"));

        let tempRate = res.data.map((el) => {
          return {
            name: el.label ? el.label + " €" : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isCountries
          ? setRateByTicketRangeCountries(sortTicketRanges(tempRate, "€"))
          : setRateByTicketRange(sortTicketRanges(tempRate, "€"));
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTicketRange(false));
  };

  const getByTokenPenetration = (body, isCountries) => {
    setIsLoadingByTokenPenetration(true);

    FetchService.post(
      "/api/dashboard/autho/geography/transactions-by-token-penetration-and-approval-rate",
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label < b.label ? 1 : a.label > b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isCountries
          ? setDataByTokenPenetrationCountries(tempData)
          : setDataByTokenPenetration(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isCountries
          ? setRateByTokenPenetrationCountries(tempRate)
          : setRateByTokenPenetration(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByTokenPenetration(false));
  };

  const getByProgram = (body, isCountries) => {
    setIsLoadingByProgram(true);

    FetchService.post(
      "/api/dashboard/autho/geography/transactions-by-program-and-approval-rate",
      body
    )
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.value ? el.value : 0,
          };
        });
        isCountries
          ? setDataByProgramCountries(tempData)
          : setDataByProgram(tempData);

        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });
        isCountries
          ? setRateByProgramCountries(tempRate)
          : setRateByProgram(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingByProgram(false));
  };

  const get3DSCompliance = (body, isCountries) => {
    setIsLoading3DSCompliance(true);
    FetchService.post("/api/dashboard/autho/geography/3DS-compliance", body)
      .then((res) => {
        isCountries
          ? setApiData3DSComplianceCountries(res.data)
          : setApiData3DSCompliance(res.data);
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );
        let tempData = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempRate = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.approval_rate ? el.approval_rate : 0,
          };
        });

        isCountries
          ? setData3DSComplianceCountries(tempData)
          : setData3DSCompliance(tempData);

        isCountries
          ? setRate3DSComplianceCountries(tempRate)
          : setRate3DSCompliance(tempRate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3DSCompliance(false));
  };

  const getExemptionTypes = (body, isCountries) => {
    setIsLoadingExemptionTypes(true);
    FetchService.post("/api/dashboard/autho/geography/exemption-types", body)
      .then((res) => {
        let rowData = res.data.map((el) => {
          return {
            exemption: el.label,
            transaction_share:
              formatNumberRound(Number(el.trx_share ? el.trx_share : 0)) + "%",
            approval_rate:
              formatNumberRound(
                Number(el.approval_rate ? el.approval_rate : 0)
              ) + "%",
          };
        });
        isCountries
          ? setDataExemptionTypesCountries(rowData)
          : setDataExemptionTypes(rowData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingExemptionTypes(false));
  };

  const getDeclineReasons = (body, isCountries) => {
    setIsLoadingDeclineReasons(true);

    FetchService.post("/api/dashboard/autho/geography/decline-reasons", body)
      .then((res) => {
        let tempData = [];
        res.data.forEach((el) => {
          tempData.push({
            name: el.label ? el.label : "Unknown",
            size: el.value ? el.value : 0,
            percent: formatNumberFull(el.percent ? el.percent : 0),
          });
        });

        let tempReasons = listDeclineReasons;
        res.data.forEach((reason) => {
          if (!tempReasons.includes(reason.label)) {
            tempReasons.push(reason.label);
          }
        });
        setListDeclineReasons(tempReasons);

        isCountries
          ? setDataDeclineReasonsCountries(tempData)
          : setDataDeclineReasons(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingDeclineReasons(false));
  };

  // donut data
  const getMerchantAdviseCodeDonut = (body, isCountries) => {
    setIsLoadingMerchantAdviseCodeDonut(true);
    FetchService.post(
      "/api/dashboard/autho/geography/merchant-advice-code-total-percentage",
      body
    )
      .then((res) => {
        let data = [];
        if (Object.keys(res.data).length !== 0) {
          data.push(
            {
              name: "Mac Present",
              value: res.data.mac_present ? res.data.mac_present : 0,
            },
            {
              name: "Mac Absent",
              value: res.data.mac_absent ? res.data.mac_absent : 0,
            }
          );
        }
        isCountries
          ? setDataMerchantAdviseCodeDonutCountries(data)
          : setDataMerchantAdviseCodeDonut(data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCodeDonut(false));
  };

  // table data
  const getMerchantAdviseCode = (body, isCountries) => {
    setIsLoadingMerchantAdviseCode(true);
    FetchService.post(
      "/api/dashboard/autho/geography/merchant-advice-code",
      body
    )
      .then((res) => {
        let tempData = [];
        res.data.map((el) => {
          tempData.push({
            label: el.label ? el.label : "Unknown",
            value: formatNumberRound(el.percent ? el.percent : 0) + "%",
          });
        });

        isCountries
          ? setDataMerchantAdviseCodeCountries(tempData)
          : setDataMerchantAdviseCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMerchantAdviseCode(false));
  };

  const handleSelectedProductType = (e) => {
    setProductType(e);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authorization/home",
    },
    { title: "Geography", link: "/authorization/geography", active: true },
    { title: "Benchmark", link: "/authorization/benchmark" },
    { title: "Acquirers", link: "/authorization/acquirers" },
    dashboardVersion2 && { title: "Issuers", link: "/authorization/issuers" },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.country === item1.country);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
          }
        : item1;
    });
  }

  useEffect(() => {
    setMergedDataSubchannel(
      mergeData(
        apiDataSubchannel,
        compareAndAddDummy(
          apiDataSubchannelCountries,
          apiDataSubchannel,
          "label",
          "value",
          "approval_rate"
        ),
        [
          { source: "value", target: "trx_share_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "value", target: "trx_share_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiDataSubchannel, apiDataSubchannelCountries]);

  useEffect(() => {
    setMergedDataTicketRange(
      mergeData(
        apiDataTicketRange,
        compareAndAddDummy(
          apiDataTicketRangeCountries,
          apiDataTicketRange,
          "label",
          "value",
          "approval_rate"
        ),
        [
          { source: "value", target: "value_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "value", target: "value_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiDataTicketRange, apiDataTicketRangeCountries]);

  useEffect(() => {
    setMergedData3DSCompliance(
      mergeData(
        apiData3DSCompliance,
        compareAndAddDummy(
          apiData3DSComplianceCountries,
          apiData3DSCompliance,
          "label",
          "trx_share",
          "approval_rate"
        ),
        [
          { source: "trx_share", target: "trx_share_all" },
          { source: "approval_rate", target: "approval_rate_all" },
        ],
        [
          { source: "trx_share", target: "trx_share_country" },
          { source: "approval_rate", target: "approval_rate_country" },
        ],
        "label"
      )
    );
  }, [apiData3DSCompliance, apiData3DSComplianceCountries]);

  useEffect(() => {
    setMergedDataDeclineReasons(
      mergeData(
        dataDeclineReasons,
        dataDeclineReasonsCountries,
        [{ source: "percent", target: "percent_all" }],
        [{ source: "percent", target: "percent_country" }],
        "name"
      )
    );
  }, [dataDeclineReasons, dataDeclineReasonsCountries]);

  return (
    <LayoutPageContainer
      title={"Authorization"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingTopFifteen,
          isLoadingKpis,
          isLoadingBySubchannel,
          isLoadingByTicketRange,
          isLoadingByTokenPenetration,
          isLoadingByProgram,
          isLoadingDeclineReasons,
          isLoading3DSCompliance,
          isLoadingExemptionTypes,
          isLoadingMerchantAdviseCode,
          isLoadingMerchantAdviseCodeDonut,
        ]}
        marginLeft={sidebarExpanded}
      />

      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {dashboardVersion2 && (
        <FilterBar>
          <LayoutCardFilter
            onChange={handleSelectedProductType}
            tooltipAcquirer=""
            options={[
              { value: "", text: "All products" },
              { value: "CR", text: "Credit" },
              { value: "DB", text: "Debit" },
              /*             { value: "PP", text: "Prepaid" },
               */
            ]}
          />
        </FilterBar>
      )}

      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Top 15 countries by transactions"}
          tooltip={
            "Top 15 issuing countries listed in descending order by the number of card not present transactions in the authorization flow.<br /><br />" +
            "For each issuing country the graph on the right shows the corresponding Approval rate (number of approved transactions divided by total transactions)."
          }
        />
        <Download
          section="AuthoGeography"
          chartName="Top 15 countries by transactions"
          data={mergeTopFifteen(dataTopFifteenTransactions, dataTopFifteenRate)}
          graphType="barChart"
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          label={"Country"}
          field1={transactionType === "count" ? "Transactions" : "Volumes"}
          value1={"value"}
          field2={"Approval Rate (%)"}
          value2={"authRate"}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      />
      <LayoutTwoColumns
        leftContent={
          <>
            <div className="text-center mb-3">
              {transactionType === "count"
                ? "Transactions (K)"
                : "Volumes (K€)"}
            </div>
            <McBarChartVertical
              background={false}
              data={dataTopFifteenTransactions}
              dataGuide={{
                y: "country",
                barsData:
                  transactionType === "count"
                    ? [{ dataName: "value", friendlyName: "Transactions" }]
                    : [{ dataName: "value", friendlyName: "Volumes" }],
              }}
              tooltipMeasureUnit={transactionType === "count" ? " K" : " K€"}
            />
          </>
        }
        rightContent={
          <>
            <div className="text-center mb-3">Approval rate (%)</div>
            <McBarChartVertical
              data={dataTopFifteenRate}
              dataGuide={{
                y: "country",
                barsData: [{ dataName: "value", friendlyName: "Rate" }],
              }}
              labels={["Rate (%)"]}
              legend
              tooltipMeasureUnit="%"
              avg={dataKpisAvg.approval_rate}
            />
          </>
        }
      />

      <hr />

      <FilterBoxSticky>
        <LayoutGeographyFilter
          options={countries}
          tooltipOverall={
            "Overall performance includes all the issuing countries.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipCountry={
            "Country performance includes the selected countries.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          selected={selectedCountries}
          onChange={handleSelectCountry}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Overall performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent:
                    (dataKpis.transaction_approved
                      ? formatNumberFull(dataKpis.transaction_approved / 1000)
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpis.transaction_denied
                      ? formatNumberFull(dataKpis.transaction_denied / 1000)
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent:
                    (dataKpis.ticket_approved
                      ? formatNumberFull(dataKpis.ticket_approved)
                      : "0") + " €",
                  righContent: "Average ticket approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpis.ticket_denied
                      ? formatNumberFull(dataKpis.ticket_denied)
                      : "0") + " €",
                  righContent: "Average ticket declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpis.approval_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Approval rate",
                  autofit: false,
                },
              ]}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by subchannel"}
                tooltip={
                  "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataBySubchannel}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitleFlex
              title="Approval rate by subchannel"
              tooltip={
                "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
              }
            ></SmallTitleFlex>
            <McProgressChartBlock
              data={padArray(rateBySubchannel, rateBySubchannelCountries)}
              palette={_variablesFour}
            />
            <hr style={{ marginBottom: "5.6rem" }} />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRange}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRange, rateByTicketRangeCountries)}
              palette={_variablesSix}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetration}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetration,
                rateByTokenPenetrationCountries
              )}
              palette={_variablesTwoOranges}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgram}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgram, rateByProgramCountries)}
              palette={_variablesTwoOranges}
            />
            {dashboardVersion2 && (
              <>
                <hr />
                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"3DS Exemptions Share"}
                      tooltip={
                        "Transaction share of various CNP compositions along with exemptions."
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={data3DSCompliance}
                        palette={_variablesSix}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <b>Transaction Share</b>
                        <McDonutSideLegend
                          data={data3DSCompliance}
                          palette={_variablesSix}
                        />
                      </div>
                    </div>
                    <Modal
                      dataBsTarget="exemptionsModal"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypes}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AuthoGeography"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypes}
                        graphType="pie"
                        label={"Exemption Type"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title="Approval Rate by 3DS exemptions"
                    tooltip={
                      "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                      "Approval rate is defined as number of successfully approved transactions over total transactions"
                    }
                  />
                  <McProgressChartBlock
                    data={rate3DSCompliance}
                    palette={_variablesSix}
                  />
                  <Button
                    text="Exemptions Details"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"exemptionsModal"}
                  />
                </div>

                <hr />

                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"Transaction share by merchant advice code (MAC)"}
                      tooltip={
                        "Share of declined transactions split by MAC Present or Absent status"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={dataMerchantAdviseCodeDonut}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <span>
                          <b>Transaction Share </b>
                          (within declined transactions)
                        </span>
                        <McDonutSideLegend
                          data={dataMerchantAdviseCodeDonut.map((el) => {
                            return { name: el.name, value: el.value };
                          })}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <Modal
                      dataBsTarget="authMethodModal"
                      title={<b>Deepdive of MAC transaction share</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colsDefMerchantAdviseCode}
                            rowData={dataMerchantAdviseCode}
                          />
                        </div>
                      }
                    >
                      <Download
                        disabled={selectedCountries.length === 0}
                        section="AuthoGeography"
                        chartName="MAC"
                        data={dataMerchantAdviseCode}
                        graphType="pie"
                        label={"MAC"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          { field: "Transaction Share", value: "value" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Button
                        text="Details"
                        style={{
                          fontSize: "12pt",
                          padding: "0.5rem",
                          marginTop: "3rem",
                          width: "15rem",
                          // marginLeft: "auto",
                        }}
                        dataBsToggle="modal"
                        dataBsTarget={"authMethodModal"}
                      />
                    </div>
                  </>
                </div>
              </>
            )}
            <hr />
            <SmallTitleFlex
              title={"Decline reasons"}
              tooltip={
                "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                "Area of each rectangle indicates the magnitude of each decline reason."
              }
            />
            <McTreemapChart
              data={dataDeclineReasons}
              listDeclineReasons={listDeclineReasons}
            />
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Country performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent:
                    (dataKpisCountries.transaction_approved
                      ? formatNumberFull(
                          dataKpisCountries.transaction_approved / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpisCountries.transaction_denied
                      ? formatNumberFull(
                          dataKpisCountries.transaction_denied / 1000
                        )
                      : "0") + " K",
                  righContent: "Transactions declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent:
                    (dataKpisCountries.ticket_approved
                      ? formatNumberFull(dataKpisCountries.ticket_approved)
                      : "0") + " €",
                  righContent: "Average ticket approved",
                  color: _variablesTwoOranges.primaryOrange,
                },
                {
                  leftContent:
                    (dataKpisCountries.ticket_denied
                      ? formatNumberFull(dataKpisCountries.ticket_denied)
                      : "0") + " €",
                  righContent: "Average ticket declined",
                  color: _variablesTwoOranges.primaryOrangeLight,
                },
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpisCountries.approval_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Approval rate",
                  autofit: false,
                },
              ]}
            />
            <hr />
            <div className="mb-4">
              <div className="d-flex justify-content-between">
                <SmallTitleFlex
                  title={"Transactions by subchannel"}
                  tooltip={
                    "Card not present transactions split by subchannel incidence: Recurring, Ecommerce, MOTO or Other.<br /><br />" +
                    "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                  }
                />
                <Download
                  disabled={selectedCountries.length === 0}
                  section="AuthoGeography"
                  chartName="Subchannel"
                  data={mergedDataSubchannel}
                  graphType="pie"
                  timeRange={
                    dateLabels[timePeriod[0]] +
                    " - " +
                    dateLabels[timePeriod[1]]
                  }
                  label={"Subchannel"}
                  productType={productType}
                  country={selectedCountries}
                  fields={[
                    { field: "Overall Share (%)", value: "trx_share_all" },
                    { field: "Country Share (%)", value: "trx_share_country" },
                    {
                      field: "Overall Approval Rate (%)",
                      value: "approval_rate_all",
                    },
                    {
                      field: "Country Approval Rate (%)",
                      value: "approval_rate_country",
                    },
                  ]}
                  iconSize="sm"
                  className="h-8 w-8"
                />
              </div>
              <McDonutChartSingle
                data={dataBySubchannelCountries}
                side={"legend"}
                palette={_variablesFour}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitleFlex
              title="Approval rate by subchannel"
              tooltip={
                "Approval rates by different subchannels: Ecommerce, MOTO, Recurring, Others Approval rate is defined as number of successfully approved transactions to total transactions"
              }
            ></SmallTitleFlex>
            <McProgressChartBlock
              data={padArray(rateBySubchannelCountries, rateBySubchannel)}
              palette={_variablesFour}
            />

            <hr style={{ marginBottom: "2rem" }} />

            <div
              className="d-flex justify-content-end"
              style={{ marginBottom: "1rem" }}
            >
              <Download
                disabled={selectedCountries.length === 0}
                section="AuthoGeography"
                chartName="TicketRange"
                data={mergedDataTicketRange}
                graphType="pie"
                label={"Ticket Range"}
                productType={productType}
                country={selectedCountries}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Overall Share (%)", value: "value_all" },
                  { field: "Country Share (%)", value: "value_country" },
                  {
                    field: "Overall Approval Rate (%)",
                    value: "approval_rate_all",
                  },
                  {
                    field: "Country Approval Rate (%)",
                    value: "approval_rate_country",
                  },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>

            <div className="mb-4">
              <SmallTitleFlex
                title={"Transaction count by ticket range"}
                tooltip={
                  "Card not present transactions split by ticket range incidence: 0€, 0.01-30€, 30.01-100€, 100.01-250€, 250.01-500€, or +500€.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTicketRangeCountries}
                side={"legend"}
                palette={_variablesSix}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by ticket range</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByTicketRangeCountries, rateByTicketRange)}
              palette={_variablesSix}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by token penetration"}
                tooltip={
                  "Card not present transactions split by tokenization incidence: tokenized or not tokenized.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByTokenPenetrationCountries}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by token penetration</SmallTitle>
            <McProgressChartBlock
              data={padArray(
                rateByTokenPenetrationCountries,
                rateByTokenPenetration
              )}
              palette={_variablesTwoOranges}
            />
            <hr />
            <div className="mb-4">
              <SmallTitleFlex
                title={"Transactions by program"}
                tooltip={
                  "Card not present transactions split by program incidence: 3DS or non 3DS.<br /><br />" +
                  "Approval rate, (calculated as number of approved transactions divided by total transactions), is represented for each variable."
                }
              />
              <McDonutChartSingle
                data={dataByProgramCountries}
                side={"legend"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <SmallTitle>Approval rate by program</SmallTitle>
            <McProgressChartBlock
              data={padArray(rateByProgramCountries, rateByProgram)}
              palette={_variablesTwoOranges}
            />
            {dashboardVersion2 && (
              <>
                <hr />
                <div className="mb-4">
                  <>
                    <div className="d-flex justify-content-between">
                      <SmallTitleFlex
                        title={"3DS Exemptions Share"}
                        tooltip={
                          "Transaction share of various CNP compositions along with exemptions."
                        }
                      />
                      <Download
                        disabled={selectedCountries.length === 0}
                        section="AuthoGeography"
                        chartName="3DS Exemptions"
                        data={mergedData3DSCompliance}
                        graphType="pie"
                        label={"3DS Exemptions"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Overall Share (%)",
                            value: "trx_share_all",
                          },
                          {
                            field: "Country Share (%)",
                            value: "trx_share_country",
                          },
                          {
                            field: "Overall Approval Rate (%)",
                            value: "approval_rate_all",
                          },
                          {
                            field: "Country Approval Rate (%)",
                            value: "approval_rate_country",
                          },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={compareAndAddDummy(
                          data3DSComplianceCountries,
                          data3DSCompliance,
                          "name",
                          "value"
                        )}
                        palette={_variablesSix}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          marginTop: "",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {data3DSComplianceCountries.length > 0 && (
                          <>
                            <b>Transaction Share</b>
                            <McDonutSideLegend
                              data={compareAndAddDummy(
                                data3DSComplianceCountries,
                                data3DSCompliance,
                                "name",
                                "value"
                              )}
                              palette={_variablesSix}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <Modal
                      dataBsTarget="exemptionsModalCountries"
                      title={<b>Non-3DS Exemption Types</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colDefs}
                            rowData={dataExemptionTypesCountries}
                          />
                        </div>
                      }
                    >
                      <Download
                        section="AuthoGeography"
                        chartName="Non-3DS Exemption Types"
                        data={dataExemptionTypesCountries}
                        graphType="pie"
                        label={"Exemption Type"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          {
                            field: "Transaction Share",
                            value: "transaction_share",
                          },
                          { field: "Approval Rate", value: "approval_rate" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                  </>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <SmallTitleFlex
                    title="Approval Rate by 3DS exemptions"
                    tooltip={
                      "Approval rate of various CNP compositions along with exemptions.<br /><br />" +
                      "Approval rate is defined as number of successfully approved transactions over total transactions"
                    }
                  />
                  <McProgressChartBlock
                    data={
                      selectedCountries.length !== 0
                        ? padArray(
                            compareAndAddDummy(
                              rate3DSComplianceCountries,
                              rate3DSCompliance,
                              "name",
                              "value"
                            ),
                            rate3DSCompliance
                          )
                        : padArray(
                            rate3DSComplianceCountries,
                            rate3DSCompliance
                          )
                    }
                    palette={_variablesSix}
                  />
                  {dataExemptionTypesCountries.length > 0 ? (
                    <Button
                      text="Exemptions Details"
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        //marginLeft:"6rem",
                      }}
                      dataBsToggle="modal"
                      dataBsTarget={"exemptionsModalCountries"}
                    />
                  ) : (
                    <div
                      style={{
                        fontSize: "12pt",
                        padding: "0.5rem",
                        marginTop: "2rem",
                        width: "15rem",
                        height: "2.6rem",
                      }}
                    ></div>
                  )}
                </div>
                <hr />
                <div className="mb-4">
                  <>
                    <SmallTitleFlex
                      title={"Transaction share by merchant advice code (MAC)"}
                      tooltip={
                        "Share of declined transactions split by MAC Present or Absent status"
                      }
                    />
                    <div
                      style={{ display: "flex" }}
                      className="flex-lg-column flex-xl-row"
                    >
                      <McDonutChartSingle
                        data={dataMerchantAdviseCodeDonutCountries}
                        palette={_variablesTwoOranges}
                        tooltipMeasureUnit="%"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        {dataMerchantAdviseCodeDonutCountries.length > 0 && (
                          <span>
                            <b>Transaction Share </b>
                            (within declined transactions)
                          </span>
                        )}
                        <McDonutSideLegend
                          data={dataMerchantAdviseCodeDonutCountries.map(
                            (el) => {
                              return { name: el.name, value: el.value };
                            }
                          )}
                          palette={_variablesTwoOranges}
                        />
                      </div>
                    </div>
                    <Modal
                      dataBsTarget="authMethodModalCountries"
                      title={<b>Deepdive of MAC transaction share</b>}
                      modalBody={
                        <div
                          style={{
                            width: "100%",
                            height: "30rem",
                            display: "flex",
                          }}
                        >
                          <TableAG
                            colDefs={colsDefMerchantAdviseCode}
                            rowData={dataMerchantAdviseCodeCountries}
                          />
                        </div>
                      }
                    >
                      <Download
                        disabled={selectedCountries.length === 0}
                        section="AuthoGeography"
                        chartName="MAC"
                        data={dataMerchantAdviseCodeCountries}
                        graphType="pie"
                        label={"MAC"}
                        productType={productType}
                        country={selectedCountries}
                        timeRange={
                          dateLabels[timePeriod[0]] +
                          " - " +
                          dateLabels[timePeriod[1]]
                        }
                        fields={[
                          { field: "Transaction Share", value: "value" },
                        ]}
                        iconSize="sm"
                        className="h-8 w-8"
                      />
                    </Modal>
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {dataMerchantAdviseCodeDonutCountries.length > 0 ? (
                        <Button
                          text="Details"
                          style={{
                            fontSize: "12pt",
                            padding: "0.5rem",
                            marginTop: "3rem",
                            width: "15rem",
                          }}
                          dataBsToggle="modal"
                          dataBsTarget={"authMethodModalCountries"}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: "12pt",
                            padding: "0.5rem",
                            marginTop: "3rem",
                            width: "15rem",
                            height: "2.6rem",
                          }}
                        ></div>
                      )}
                    </div>
                  </>
                </div>
              </>
            )}
            <hr />
            <div className="d-flex justify-content-between">
              <SmallTitleFlex
                title={"Decline reasons"}
                tooltip={
                  "Incidence of each decline reason on total number of declined transactions (card not present channel only). " +
                  "Area of each rectangle indicates the magnitude of each decline reason."
                }
              />
              <Download
                disabled={selectedCountries.length === 0}
                section="AuthoGeography"
                chartName="FailureReasons"
                data={mergedDataDeclineReasons}
                graphType="decline"
                label={"Decline Reason"}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Percentage Overall (%)", value: "percent_all" },
                  { field: "Percentage Country (%)", value: "percent_country" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </div>
            <McTreemapChart
              data={dataDeclineReasonsCountries}
              listDeclineReasons={listDeclineReasons}
            />
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthorizationGeography;
