import { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../services/AccountService";
import FetchService from "../../services/FetchService";
import {
  generateLastTwelveMonths,
  convertMonthLabelForApi,
  formatNumberFull,
  formatNumberRound,
  formatErrorForAlert,
  titleCase,
  sortSubchannelsList,
  assembleBody,
  compareAndAddDummy,
  mergeData,
  padArray,
  ukMerchants,
} from "../../services/UtilsService";
import LayoutPageContainer from "../../components/Layout/LayoutPageContainer";
import LayoutIssuersFilter from "../../components/Layout/LayoutIssuersFilter";
import LayoutIssuersSingleFilter from "../../components/Layout/LayoutIssuersSingleFilter";
import LayoutTwoColumns from "../../components/Layout/LayoutTwoColumns";
import TimePeriod from "../../components/TimePeriod/TimePeriod";
import FilterBoxSticky from "../../components/FilterBox/FilterBoxSticky";
import Refreshing from "../../components/Refreshing/Refreshing";
import Title from "../../components/Title/Title";
import SmallTitle from "../../components/Title/SmallTitle";
import SmallTitleFlex from "../../components/Title/SmallTitleFlex";
import KpiColumn from "../../components/Kpi/KpiColumn";
import KpiProgressChart from "../../components/Kpi/KpiProgressChart";
import McBarChartVertical from "../../components/McCharts/McBarChartVertical";
import McDonutChartSingle from "../../components/McCharts/McDonutChartSingle";
import McDonutSideLegend from "../../components/McCharts/McDonutSideLegend";
import McProgressChartBlock from "../../components/McCharts/McProgressChartBlock";
import {
  _variablesFour,
  _variablesTwelve,
  _variablesThreeOrangesRate,
  _variablesSix,
  _variablesTwoOranges,
} from "../../styles/_variables";
import FilterBar from "../../components/FilterBox/FilterBar";
import Modal from "../../components/Modal/Modal";
import TableAG from "../../components/Table/TableAG";
import Button from "../../components/Button/Button";
import Switcher from "../../components/Switcher/Switcher";
import Download from "../../components/Button/Download";

const colsDef = [
  { field: "auth_method", headerName: "Authentication Method", flex: 1 },
  {
    headerName: "Challenge Code",
    marryChildren: true,
    children: [
      {
        field: "percent_zerofour",
        headerName: "04",
        flex: 1,
      },
      { field: "percent_zerofive", headerName: "05", flex: 1 },
    ],
  },
  {
    field: "total_trx",
    headerName: "Total Transaction Count",
    flex: 1,
    valueFormatter: (params) => Number(params.value).toLocaleString("en-US"),
  },
];

const AuthenticationIssuers = () => {
  const {
    merchantName,
    sidebarExpanded,
    acquirerName,
    isMultiAcquirer,
    timePeriod,
    setTimePeriod,
    userEmail,
    currency,
    autheDates,
  } = useContext(AccountContext);
  const [alert, setAlert] = useState({});
  const [dateLabels, setDateLabels] = useState([]);
  // acquirers
  const [isLoadingAcquirers, setIsLoadingAcquirers] = useState(true);
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirer, setSelectedAcquirer] = useState("");
  // issuers
  const [issuers, setIssuers] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState();
  // top fifteen issuers
  const [isLoadingTopFifteen, setIsLoadingTopFifteen] = useState(false);
  const [dataTopFifteenTransactions, setDataTopFifteenTransactions] = useState(
    []
  );
  const [dataTopFifteenRate, setDataTopFifteenRate] = useState([]);
  // transactions kpi's
  const [isLoadingKpis, setIsLoadingKpis] = useState(false);
  const [dataKpis, setDataKpis] = useState([]);
  const [dataKpisIssuers, setDataKpisIssuers] = useState([]);
  const [dataKpisAvg, setDataKpisAvg] = useState([]);
  // transactions per device
  const [isLoadingTransactionsDevice, setIsLoadingTransactionsDevice] =
    useState(false);
  const [dataTransactionsDevice, setDataTransactionsDevice] = useState([]);
  const [sideBarsTransactionsDevice, setSideBarsTransactionsDevice] = useState(
    []
  );
  const [dataTransactionsDeviceIssuers, setDataTransactionsDeviceIssuers] =
    useState([]);
  const [
    sideBarsTransactionsDeviceIssuers,
    setSideBarsTransactionsDeviceIssuers,
  ] = useState([]);

  // 3ds2 versions
  const [isLoading3ds2Versions, setIsLoading3ds2Versions] = useState(true);
  const [data3ds2Versions, setData3ds2Versions] = useState([]);
  const [data3ds2VersionsBarChart, setData3ds2VersionsBarChart] = useState([]);
  const [data3ds2VersionsIssuers, setData3ds2VersionsIssuers] = useState([]);
  const [data3ds2VersionsBarChartIssuers, setData3ds2VersionsBarChartIssuers] =
    useState([]);

  // auth methods
  const [isLoadingAuthMethods, setIsLoadingAuthMethods] = useState(true);
  const [dataAuthMethods, setDataAuthMethods] = useState([]);
  const [dataTopFiveAuthMethods, setDataTopFiveAuthMethods] = useState([]);
  const [dataAuthMethodsIssuers, setDataAuthMethodsIssuers] = useState([]);
  const [dataTopFiveAuthMethodsIssuers, setDataTopFiveAuthMethodsIssuers] =
    useState([]);
  // challenge code
  const [dataChallengeCode, setDataChallengeCode] = useState([]);
  const [dataChallengeCodeIssuers, setDataChallengeCodeIssuers] = useState([]);
  const [isLoadingChallengeCode, setIsLoadingChallengeCode] = useState();
  const [dataChallengeCodeForAuthMethod, setDataChallengeCodeForAuthMethod] =
    useState([]);
  const [
    dataChallengeCodeForAuthMethodIssuers,
    setDataChallengeCodeForAuthMethodIssuers,
  ] = useState([]);
  const [
    isLoadingChallengeCodeForAuthMethod,
    setIsLoadingChallengeCodeForAuthMethod,
  ] = useState();
  // transaction type
  const [transactionType, setTransactionType] = useState("count");
  //api response
  const [apiDataAuthMethods, setApiDataAuthMethods] = useState([]);
  const [apiDataAuthMethodsIssuers, setApiDataAuthMethodsIssuers] = useState(
    []
  );
  //merged data
  const [mergedDataAuthMethods, setMergedDataAuthMethods] = useState([]);

  const currencySymbol = currency === 826 ? "£" : "€";

  // get dates for timeperiod
  useEffect(() => {
    if (autheDates) {
      setDateLabels(autheDates);
    } else {
      setAlert({
        message: "Authentication dates not available",
        messageType: "error",
      });
    }
  }, [autheDates]);

  // get acquirers and merchant kpis for selected timeperiod
  useEffect(() => {
    isLoadingAll();
    const timeOut = setTimeout(() => {
      if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
        let body = assembleBody(
          merchantName,
          dateLabels[timePeriod[0]],
          dateLabels[timePeriod[1]],
          selectedAcquirer,
          transactionType === "count" ? false : true,
          undefined,
          currency
        );
        getAcquirers(body);
        getTopFifteenIssuers(body);
        getKpis(body);
        getKpisAvg(body);
        getTransactionsDevice(body);
        get3ds2Versions(body);
        getAuthMethods(body);
        getChallengeCode(body);
        getChallengeCodeForAuthMethod(body);
        getKpis(body, true);
        getTransactionsDevice(body, true);
        get3ds2Versions(body, true);
        getAuthMethods(body, true);
        getChallengeCode(body, true);
        getChallengeCodeForAuthMethod(body, true);
      }
    }, 1500);
    return () => clearTimeout(timeOut);
  }, [dateLabels, timePeriod, selectedAcquirer]);

  // get acquirers and merchant kpis for selected timeperiod
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBody(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        selectedAcquirer,
        transactionType === "count" ? false : true,
        undefined,
        currency
      );

      getTopFifteenIssuers(body);
      getKpisAvg(body);
    }
  }, [transactionType]);

  // get charts data based on current issuer
  useEffect(() => {
    if (dateLabels[timePeriod[0]] && dateLabels[timePeriod[1]]) {
      let body = assembleBodyIssuer(
        merchantName,
        dateLabels[timePeriod[0]],
        dateLabels[timePeriod[1]],
        selectedAcquirer,
        transactionType === "count" ? false : true,
        undefined,
        currency
      );

      getKpis(body, true);
      setIsLoadingKpis(false);
      getTransactionsDevice(body, true);
      get3ds2Versions(body, true);
      getAuthMethods(body, true);
      getChallengeCode(body, true);
      getChallengeCodeForAuthMethod(body, true);
    } else {
      clearChartsCountries();
    }
  }, [selectedIssuer]);

  const getAcquirers = (body) => {
    setIsLoadingAcquirers(true);
    FetchService.post("api/dashboard/acquirers/authe", body)
      .then((res) => {
        let tempAcquirers = res.data.acquirers.sort((a, b) =>
          a.localeCompare(b)
        );
        tempAcquirers = tempAcquirers.map((el) => {
          return {
            value: el,
            text: el,
          };
        });
        tempAcquirers = [
          { value: "", text: "All Acquirers" },
          ...tempAcquirers,
        ];
        setAcquirers(tempAcquirers);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAcquirers(false));
  };

  const getTopFifteenIssuers = (body) => {
    setIsLoadingTopFifteen(true);
    FetchService.post(
      "api/dashboard/authe/issuers/top-15-issuers-by-transactions",
      body
    )
      .then((res) => {
        let tempTransactions = [];
        let tempRate = [];
        let tempIssuers = [];

        res.data.forEach((el) => {
          tempTransactions.push({
            issuer: el.issuer_name ? titleCase(el.issuer_name) : "",
            value: el.sum_trx ? el.sum_trx / 1000 : 0,
          });
          tempRate.push({
            issuer: el.issuer_name ? titleCase(el.issuer_name) : "",
            value: el.auth_rate ? el.auth_rate : 0,
            avg: el.above_average ? el.above_average : 0,
          });
          tempIssuers.push({
            value: el.issuer_name ? el.issuer_name : "",
            text: el.issuer_name ? el.issuer_name : "",
          });
        });

        setDataTopFifteenTransactions(tempTransactions);
        setDataTopFifteenRate(tempRate);
        setIssuers([{ value: "", text: "All Issuers" }, ...tempIssuers]);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTopFifteen(false));
  };

  const getKpis = (body, isIssuer = false) => {
    setIsLoadingKpis(true);
    FetchService.post("/api/dashboard/authe/issuers/performance", body)
      .then((res) => {
        isIssuer ? setDataKpisIssuers(res.data) : setDataKpis(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getKpisAvg = (body) => {
    setIsLoadingKpis(true);
    FetchService.post("/api/dashboard/authe/issuers/performance", body)
      .then((res) => {
        setDataKpisAvg(res.data);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingKpis(false));
  };

  const getTransactionsDevice = (body, isIssuer) => {
    setIsLoadingTransactionsDevice(true);
    FetchService.post(
      "/api/dashboard/authe/issuers/transactions-per-device",
      body
    )
      .then((res) => {
        let tempData = [];
        let tempBars = [];

        let web = res.data.find((el) => el.label === "Browser-based");
        tempData.push({
          name: "Web",
          value: web ? web.value : "0",
        });
        tempBars.push({
          value: web?.auth_rate ? web.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrange,
          text: "Web",
        });

        let app = res.data.find((el) => el.label === "App-based");
        tempData.push({
          name: "App",
          value: app ? app.value : "0",
        });
        tempBars.push({
          value: app ? app.auth_rate : "0",
          color: _variablesThreeOrangesRate.primaryOrangeLight,
          text: "App",
        });

        if (isIssuer) {
          setDataTransactionsDeviceIssuers(tempData);
          setSideBarsTransactionsDeviceIssuers(tempBars);
        } else {
          setDataTransactionsDevice(tempData);
          setSideBarsTransactionsDevice(tempBars);
        }
      })
      .catch((err) => {
        console.log("error tranctions", err);

        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingTransactionsDevice(false));
  };

  const getAuthMethods = (body, isIssuer) => {
    setIsLoadingAuthMethods(true);
    FetchService.post(
      "/api/dashboard/authe/issuers/authentication-methods",
      body
    )
      .then((res) => {
        let cleanRes = res.data;

        isIssuer
          ? setApiDataAuthMethodsIssuers(cleanRes)
          : setApiDataAuthMethods(cleanRes);

        let tempAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.trx_share ? el.trx_share : 0,
          };
        });
        let tempTopFiveAuthMethods = cleanRes.map((el) => {
          return {
            name: el.label ? el.label : "",
            value: el.success_rate ? el.success_rate : 0,
          };
        });

        if (isIssuer) {
          setDataAuthMethodsIssuers(tempAuthMethods);
          setDataTopFiveAuthMethodsIssuers(tempTopFiveAuthMethods);
        } else {
          setDataAuthMethods(tempAuthMethods);
          setDataTopFiveAuthMethods(tempTopFiveAuthMethods);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingAuthMethods(false));
  };

  const getChallengeCode = (body, isIssuer) => {
    setIsLoadingChallengeCode(true);
    FetchService.post("/api/dashboard/authe/issuers/challenge-code", body)
      .then((res) => {
        let tempData = res.data.map((el) => {
          return {
            label: el.label ? el.label : "",
            value: el.percent ? el.percent : 0,
          };
        });
        isIssuer
          ? setDataChallengeCodeIssuers(tempData)
          : setDataChallengeCode(tempData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCode(false));
  };

  const getChallengeCodeForAuthMethod = (body, isIssuer) => {
    setIsLoadingChallengeCodeForAuthMethod(true);
    FetchService.post(
      "/api/dashboard/authe/issuers/challenge-code-for-auth-method",
      body
    )
      .then((res) => {
        let tempData = res.data;
        let groupedData = [];

        // group records with the same auth method to display them in the table
        for (let i = 0; i < tempData.length - 2; i += 1) {
          if (tempData[i].auth_method === tempData[i + 1].auth_method) {
            let percent_zerofour = "-";
            let percent_zerofive = "-";

            if (res.data[i].challenge_desc.substring(0, 2) === "04") {
              percent_zerofour = formatNumberRound(res.data[i].percent) + "%";
              percent_zerofive =
                formatNumberRound(res.data[i + 1].percent) + "%";
            } else if (res.data[i].challenge_desc.substring(0, 2) === "05") {
              percent_zerofour =
                formatNumberRound(res.data[i + 1].percent) + "%";
              percent_zerofive = formatNumberRound(res.data[i].percent) + "%";
            }
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: percent_zerofour,
              percent_zerofive: percent_zerofive,
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
            // skip next record since you grouped it with the current one
            i += 1;
          }
          // in case the record has no matching 04 type or 05 type record
          else if (res.data[i].challenge_desc.substring(0, 2) === "04") {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: formatNumberRound(res.data[i].percent) + "%",
              percent_zerofive: "-",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          } else {
            groupedData.push({
              auth_method: res.data[i].auth_method,
              percent_zerofour: "-",
              percent_zerofive: formatNumberRound(res.data[i].percent) + "%",
              total_trx: formatNumberRound(res.data[i].total_trx),
            });
          }
        }

        groupedData.sort((a, b) => b.total_trx - a.total_trx);

        isIssuer
          ? setDataChallengeCodeForAuthMethodIssuers(groupedData)
          : setDataChallengeCodeForAuthMethod(groupedData);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingChallengeCodeForAuthMethod(false));
  };

  const get3ds2Versions = (body, isIssuer) => {
    setIsLoading3ds2Versions(true);
    FetchService.post("/api/dashboard/authe/issuers/3ds2-versions", body)
      .then((res) => {
        let cleanRes = res.data.sort((a, b) =>
          a.label > b.label ? 1 : a.label < b.label ? -1 : 0
        );

        let temp3ds2Versions = cleanRes
          .filter((el) => el.trx_share >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.trx_share ? el.trx_share : 0,
            };
          });

        let temp3ds2VersionsBarChart = cleanRes
          .filter((el) => el.success_rate >= 0.1)
          .map((el) => {
            return {
              name: el.label ? el.label : "",
              value: el.success_rate ? el.success_rate : 0,
            };
          });

        if (isIssuer) {
          setData3ds2VersionsIssuers(temp3ds2Versions);
          setData3ds2VersionsBarChartIssuers(temp3ds2VersionsBarChart);
        } else {
          setData3ds2Versions(temp3ds2Versions);
          setData3ds2VersionsBarChart(temp3ds2VersionsBarChart);
        }
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoading3ds2Versions(false));
  };

  const assembleBodyIssuer = () => {
    return {
      merchant_name: merchantName,
      start_date: convertMonthLabelForApi(dateLabels[timePeriod[0]]),
      end_date: convertMonthLabelForApi(dateLabels[timePeriod[1]]),
      issuer_name: selectedIssuer,
      acquirer_name: selectedAcquirer,
      currency: currency,
    };
  };

  const isLoadingAll = () => {
    setIsLoadingTopFifteen(true);
    setIsLoadingKpis(true);
    setIsLoadingTransactionsDevice(true);
  };

  const clearChartsCountries = () => {
    setDataKpis([]);
    setDataKpisIssuers([]);
    setDataTransactionsDevice([]);
    setDataTransactionsDeviceIssuers([]);
    setData3ds2VersionsIssuers([]);
    setData3ds2VersionsBarChartIssuers([]);
    setDataAuthMethodsIssuers([]);
    setDataTopFiveAuthMethodsIssuers([]);
    setDataChallengeCodeIssuers([]);
    setDataChallengeCodeForAuthMethodIssuers([]);
  };

  const padArray = (arrayToDisplay, arrayToCompare) => {
    let paddedArrayToDisplay = [...arrayToDisplay];

    if (arrayToCompare.length > arrayToDisplay.length) {
      for (
        let index = 0;
        index < arrayToCompare.length - arrayToDisplay.length;
        index++
      ) {
        paddedArrayToDisplay.push({
          name: "",
          value: 0,
          visibility: "hidden",
        });
      }
    }

    return paddedArrayToDisplay;
  };

  const handleSelectAcquirer = (e) => {
    setSelectedAcquirer(e);
    handleSelectIssuer("");
  };

  const handleSelectIssuer = (e) => {
    setSelectedIssuer(e);
  };

  let pages = [
    {
      title: "Overview",
      link: "/authentication/home",
    },
    { title: "Geography", link: "/authentication/geography" },
    { title: "Benchmark", link: "/authentication/benchmark" },
    { title: "Acquirers", link: "/authentication/acquirers" },
    { title: "Issuers", link: "/authentication/issuers", active: true },
  ];

  if (!isMultiAcquirer && !acquirerName) {
    pages = pages.filter((el) => el.title !== "Acquirers");
  }

  function mergeTopFifteen(array1, array2) {
    return array1.map((item1) => {
      const item2 = array2.find((item2) => item2.issuer === item1.issuer);
      return item2
        ? {
            ...item1,
            authRate: item2.value,
          }
        : item1;
    });
  }

  useEffect(() => {
    setMergedDataAuthMethods(
      mergeData(
        apiDataAuthMethods,
        apiDataAuthMethodsIssuers,
        [
          { source: "trx_share", target: "trx_share_merchant" },
          { source: "success_rate", target: "success_rate_merchant" },
        ],
        [
          { source: "trx_share", target: "trx_share_issuer" },
          { source: "success_rate", target: "success_rate_issuer" },
        ],
        "label"
      )
    );
  }, [apiDataAuthMethods, apiDataAuthMethodsIssuers]);

  return (
    <LayoutPageContainer
      title={"Authentication"}
      pages={pages}
      alert={alert}
      setAlert={setAlert}
    >
      <Refreshing
        conditions={[
          isLoadingAcquirers,
          isLoadingTopFifteen,
          isLoadingKpis,
          isLoadingTransactionsDevice,
          isLoading3ds2Versions,
          isLoadingAuthMethods,
          isLoadingChallengeCode,
          isLoadingChallengeCodeForAuthMethod,
        ]}
        marginLeft={sidebarExpanded}
      />
      <TimePeriod
        dateLabels={dateLabels}
        timePeriod={timePeriod}
        setTimePeriod={setTimePeriod}
      />

      {/* first filter */}
      {isMultiAcquirer && (
        <FilterBar>
          <LayoutIssuersSingleFilter
            options={acquirers}
            tooltipAcquirer={""}
            onChange={handleSelectAcquirer}
            hideNames={userEmail === "demo@mastercard.it"}
          />
        </FilterBar>
      )}

      <hr />
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Switcher
          pages={[
            {
              title: "Transactions",
              onClick: () => {
                setTransactionType("count");
              },
            },
            {
              title: "Volumes",
              onClick: () => {
                setTransactionType("volume");
              },
            },
          ]}
        />
      </div> */}
      <div className="d-flex justify-content-between">
        <SmallTitleFlex
          title={"Top 15 issuers by transactions"}
          tooltip={
            "Top 15 Issuers listed in descending order by number of transactions."
          }
        />
        <Download
          section="AutheIssuers"
          chartName="Top 15 issuers by transactions"
          data={mergeTopFifteen(dataTopFifteenTransactions, dataTopFifteenRate)}
          graphType="barChart"
          timeRange={
            dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
          }
          acquirer={selectedAcquirer}
          label={"Issuer"}
          field1={transactionType === "count" ? "Transactions" : "Volumes"}
          value1={"value"}
          field2={"Authentication Rate (%)"}
          value2={"authRate"}
          iconSize="sm"
          className="h-8 w-8"
        />
      </div>
      {/* <Switcher
        pages={[
          {
            title: "Transactions",
            onClick: () => {
              setTransactionType("count");
            },
          },
          {
            title: "Volumes",
            onClick: () => {
              setTransactionType("volume");
            },
          },
        ]}
      /> */}
      <LayoutTwoColumns
        leftContent={
          <>
            <div className="text-center mb-3 fw-semibold ">
              {transactionType === "count"
                ? "Attempted Transactions (K)"
                : "Attempted Volume (K)"}
            </div>
            <McBarChartVertical
              background={false}
              data={dataTopFifteenTransactions}
              dataGuide={{
                y: "issuer",
                barsData: [{ dataName: "value", friendlyName: "Transactions" }],
              }}
              tooltipMeasureUnit=" K"
              hideNamesIssuers={userEmail === "demo@mastercard.it"}
            />
          </>
        }
        rightContent={
          <>
            <div className="text-center mb-3">Success Rate (%)</div>
            <McBarChartVertical
              data={dataTopFifteenRate}
              dataGuide={{
                y: "issuer",
                barsData: [{ dataName: "value", friendlyName: "Rate" }],
              }}
              labels={["Rate (%)"]}
              legend
              tooltipMeasureUnit="%"
              avg={dataKpisAvg.auth_rate}
              hideNamesIssuers={userEmail === "demo@mastercard.it"}
            />
          </>
        }
      />

      <FilterBoxSticky>
        <LayoutIssuersFilter
          acquirer={selectedAcquirer}
          tooltipMerchant={
            "Merchant performance includes the overall performances.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          tooltipIssuer={
            "Issuer performance indicates the performances of selected Issuer.<br /><br />" +
            "Approval rate is calculated as: number of approved transactions divided by total transactions.<br /><br />" +
            "Average ticket is calculated as: volumes (spend) divided by transactions."
          }
          options={issuers}
          onChange={handleSelectIssuer}
          hideNames={userEmail === "demo@mastercard.it"}
        />
      </FilterBoxSticky>

      <LayoutTwoColumns
        split
        leftContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Overall performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpis.auth_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Authentication rate",
                  autofit: false,
                },
              ]}
            />
            {!ukMerchants.includes(merchantName) && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpis.avg_ticket_ok
                        ? formatNumberFull(dataKpis.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}
            <hr />
            <div className="mb-5">
              <SmallTitleFlex
                title={"3DS Versions Share"}
                tooltip={
                  "The shares of card not present transactions split by 3DS versions"
                }
              />
              <div
                style={{ display: "flex" }}
                className="flex-lg-column flex-xl-row"
              >
                <McDonutChartSingle
                  data={data3ds2Versions}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
                <div
                  style={{
                    marginTop: "",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <b>Transaction Share</b>
                  <McDonutSideLegend
                    data={data3ds2Versions}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            </div>
            <div className="mb-5">
              <SmallTitleFlex
                title={"3DS Versions Success Rate"}
                tooltip={
                  "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                }
              />
              <McProgressChartBlock
                data={padArray(
                  data3ds2VersionsBarChart,
                  data3ds2VersionsBarChartIssuers
                )}
                palette={_variablesTwoOranges}
              />
            </div>
            <hr />
            <div className="mb-5">
              <SmallTitleFlex
                title={"Transaction share per device"}
                tooltip={
                  "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                }
              />
              <McDonutChartSingle
                data={dataTransactionsDevice}
                side={"bars"}
                sideBars={sideBarsTransactionsDevice}
                sideBarsTitle={"Authentication rate"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>
            <>
              <hr style={{ marginBottom: "5.6rem" }} />
              <div>
                <SmallTitleFlex
                  title={"Transaction share of authentication methods"}
                  tooltip={
                    "Transaction share by different authentication methods"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={dataAuthMethods}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <McDonutSideLegend
                      data={dataAuthMethods}
                      palette={_variablesSix}
                    />
                  </div>
                </div>
              </div>

              <div
                className="mt-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <SmallTitleFlex
                  title={"Authentication Rate for top 5 authentication methods"}
                  tooltip={
                    "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                    "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                  }
                />
                <McProgressChartBlock
                  data={dataTopFiveAuthMethods}
                  palette={_variablesSix}
                />
                <Button
                  text="Auth method performance by challenge code"
                  style={{
                    fontSize: "12pt",
                    padding: "0.5rem",
                    marginTop: "2rem",
                    width: "15rem",
                    //marginLeft:"6rem",
                  }}
                  dataBsToggle="modal"
                  dataBsTarget={"authMethodModal"}
                />
              </div>
            </>

            <Modal
              dataBsTarget="authMethodModal"
              title={<b>Top 5 Authentication Methods by Challenge Code</b>}
              modalBody={
                <div
                  style={{
                    width: "100%",
                    height: "29rem",
                    display: "flex",
                    gap: "8rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <McDonutChartSingle
                      data={dataChallengeCode}
                      palette={_variablesTwoOranges}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <b>Transaction Failed due to ACS Timeout</b>
                      <McDonutSideLegend
                        data={dataChallengeCode.map((el) => {
                          return { name: el.label, value: el.value };
                        })}
                        palette={_variablesTwoOranges}
                      />
                    </div>
                  </div>
                  <TableAG
                    colDefs={colsDef}
                    rowData={dataChallengeCodeForAuthMethod.slice(0, 5)}
                  />
                </div>
              }
            >
              <Download
                section="AutheIssuers"
                chartName="Authentication Methods by Challenge Code"
                data={dataChallengeCodeForAuthMethod}
                graphType="pie"
                label={"Authentication Method"}
                acquirer={selectedAcquirer}
                issuer={selectedIssuer}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Percent 05", value: "percent_zerofive" },
                  { field: "Percent 04", value: "percent_zerofour" },
                  { field: "Total", value: "total_trx" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </Modal>
          </>
        }
        rightContent={
          <>
            <Title styleClass="d-block d-lg-none mb-3">
              Issuer performance
            </Title>
            <KpiColumn
              kpis={[
                {
                  leftContent: (
                    <KpiProgressChart
                      bar={{
                        value: dataKpisIssuers.auth_rate,
                        color: _variablesTwoOranges.primaryOrange,
                      }}
                    />
                  ),
                  righContent: "Authentication rate",
                  autofit: false,
                },
              ]}
            />
            {!ukMerchants.includes(merchantName) && (
              <KpiColumn
                kpis={[
                  {
                    leftContent:
                      (dataKpisIssuers.avg_ticket_ok
                        ? formatNumberFull(dataKpisIssuers.avg_ticket_ok)
                        : "0") + ` ${currencySymbol}`,
                    righContent: (
                      <div>
                        <b>Average ticket</b>
                        <br />
                        Authentication success
                      </div>
                    ),
                    color: _variablesTwoOranges.primaryOrange,
                  },
                ]}
              />
            )}

            <hr />
            <div className="mb-5">
              <SmallTitleFlex
                title={"3DS Versions Share"}
                tooltip={
                  "The shares of card not present transactions split by 3DS versions"
                }
              />
              <div
                style={{ display: "flex" }}
                className="flex-lg-column flex-xl-row"
              >
                <McDonutChartSingle
                  data={data3ds2VersionsIssuers}
                  palette={_variablesTwoOranges}
                  tooltipMeasureUnit="%"
                />
                <div
                  style={{
                    marginTop: "",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {data3ds2VersionsIssuers.length > 0 && (
                    <b>Transaction Share</b>
                  )}
                  <McDonutSideLegend
                    data={data3ds2VersionsIssuers}
                    palette={_variablesTwoOranges}
                  />
                </div>
              </div>
            </div>
            <div className="mb-5">
              <SmallTitleFlex
                title={"3DS Versions Success Rate"}
                tooltip={
                  "Success Rate is defined as: number of successfully authenticated transactions over total transactions. Success rate split by different 3DS versions"
                }
              />
              <McProgressChartBlock
                data={padArray(
                  data3ds2VersionsBarChartIssuers,
                  data3ds2VersionsBarChart
                )}
                palette={_variablesTwoOranges}
              />
            </div>

            <hr />
            <div className="mb-5">
              <SmallTitleFlex
                title={"Transaction share per device"}
                tooltip={
                  "Card not present transactions split by device: Web or App Authentication rate is defined as number of successfully authenticated transactions over total transactions"
                }
              />
              <McDonutChartSingle
                data={dataTransactionsDeviceIssuers}
                side={"bars"}
                sideBars={sideBarsTransactionsDeviceIssuers}
                sideBarsTitle={"Authentication rate"}
                palette={_variablesTwoOranges}
                tooltipMeasureUnit="%"
              />
            </div>

            <>
              <hr style={{ marginBottom: "2rem" }} />
              <div>
                <div
                  className="d-flex justify-content-end"
                  style={{ marginBottom: "1rem" }}
                >
                  <Download
                    section="AutheIssuers"
                    chartName="AuthMethod"
                    data={mergedDataAuthMethods}
                    graphType="pie"
                    label={"Auth Method"}
                    timeRange={
                      dateLabels[timePeriod[0]] +
                      " - " +
                      dateLabels[timePeriod[1]]
                    }
                    acquirer={selectedAcquirer}
                    issuer={selectedIssuer}
                    fields={[
                      {
                        field: "Transaction Share Merchant (%)",
                        value: "trx_share_merchant",
                      },
                      {
                        field: "Transaction Share Issuer (%)",
                        value: "trx_share_issuer",
                      },
                      {
                        field: "Success Rate Merchant (%)",
                        value: "success_rate_merchant",
                      },
                      {
                        field: "Success Rate Issuer (%)",
                        value: "success_rate_issuer",
                      },
                    ]}
                    iconSize="sm"
                    className="h-8 w-8"
                  />
                </div>
                <SmallTitleFlex
                  title={"Transaction share of authentication methods"}
                  tooltip={
                    "Transaction share by different authentication methods"
                  }
                />
                <div
                  style={{ display: "flex" }}
                  className="flex-lg-column flex-xl-row"
                >
                  <McDonutChartSingle
                    data={compareAndAddDummy(
                      dataAuthMethodsIssuers,
                      dataAuthMethods,
                      "name",
                      "value"
                    )}
                    palette={_variablesSix}
                    tooltipMeasureUnit="%"
                  />
                  <div
                    style={{
                      marginTop: "",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <McDonutSideLegend
                      data={compareAndAddDummy(
                        dataAuthMethodsIssuers,
                        dataAuthMethods,
                        "name",
                        "value"
                      )}
                      palette={_variablesSix}
                    />
                  </div>
                </div>
              </div>

              <div
                className="mt-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <SmallTitleFlex
                  title={"Authentication Rate for top 5 authentication methods"}
                  tooltip={
                    "Top 5 authentication methods sorted in descending order of total authentications.<br /><br />" +
                    "Authentication rate is defined as number of successfully authenticated transactions over total number of transactions."
                  }
                />
                <McProgressChartBlock
                  data={padArray(
                    compareAndAddDummy(
                      dataTopFiveAuthMethodsIssuers,
                      dataTopFiveAuthMethods,
                      "name",
                      "value"
                    ),
                    dataTopFiveAuthMethods
                  )}
                  palette={_variablesSix}
                />
                {dataChallengeCodeIssuers.length > 0 ? (
                  <Button
                    text="Auth method performance by challenge code"
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      //marginLeft:"6rem",
                    }}
                    dataBsToggle="modal"
                    dataBsTarget={"authMethodModalIssuers"}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "12pt",
                      padding: "0.5rem",
                      marginTop: "2rem",
                      width: "15rem",
                      height: "2.6rem",
                      //marginLeft:"6rem",
                    }}
                  ></div>
                )}
              </div>
            </>

            <Modal
              dataBsTarget="authMethodModalIssuers"
              title={<b>Top 5 Authentication Methods by Challenge Code</b>}
              modalBody={
                <div
                  style={{
                    width: "100%",
                    height: "29rem",
                    display: "flex",
                    gap: "8rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <McDonutChartSingle
                      data={dataChallengeCodeIssuers}
                      palette={_variablesTwoOranges}
                      tooltipMeasureUnit="%"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <b>Transaction Failed due to ACS Timeout</b>
                      <McDonutSideLegend
                        data={dataChallengeCodeIssuers.map((el) => {
                          return { name: el.label, value: el.value };
                        })}
                        palette={_variablesTwoOranges}
                      />
                    </div>
                  </div>
                  <TableAG
                    colDefs={colsDef}
                    rowData={dataChallengeCodeForAuthMethodIssuers.slice(0, 5)}
                  />
                </div>
              }
            >
              <Download
                section="AutheIssuers"
                chartName="Authentication Methods by Challenge Code"
                data={dataChallengeCodeForAuthMethodIssuers}
                graphType="pie"
                label={"Authentication Method"}
                acquirer={selectedAcquirer}
                issuer={selectedIssuer}
                timeRange={
                  dateLabels[timePeriod[0]] + " - " + dateLabels[timePeriod[1]]
                }
                fields={[
                  { field: "Percent 05", value: "percent_zerofive" },
                  { field: "Percent 04", value: "percent_zerofour" },
                  { field: "Total", value: "total_trx" },
                ]}
                iconSize="sm"
                className="h-8 w-8"
              />
            </Modal>
          </>
        }
      />
    </LayoutPageContainer>
  );
};

export default AuthenticationIssuers;
